export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
  Cursor: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Map: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  validatePasswordSecurity: PasswordValidateResponse;
  getPartnerEmployee?: Maybe<PartnerEmployee>;
  sendAuthLink?: Maybe<SendAuthLinkResponse>;
  getPartner?: Maybe<Partner>;
  getPartners: GetPartnersResult;
  getPartnerPlans?: Maybe<Array<PartnerPlan>>;
  getPendingPartnerEmployees?: Maybe<GetPendingPartnerEmployeesResponse>;
  conversationsV2: ConversationsResultV2;
  conversationV2: ConversationV2;
  conversationTopics: Array<ConversationTopic>;
  documentV2: DocumentV2;
  documentsV2: Array<DocumentV2>;
  documentsByFoldersV2: Array<DocumentV2>;
  searchDrugsV2: DrugV2Result;
  drugV2: DrugV2;
  visitNoteV2?: Maybe<VisitNoteV2>;
  visitNotesV2: Array<VisitNoteV2>;
  medicationsV2: Array<MedicationV2>;
  medicationV2: MedicationV2;
  messagingMigrationTracking: MessagingMigrationResult;
  encounterMigrationTracking: EncounterMigrationResult;
  documentMigrationTracking: DocumentMigrationResult;
  patientV2: PatientV2;
  adtV2: Adtv2;
  patientTagsV2: PatientTagV2Result;
  emrProvider: Provider;
  emrProviders: EmrProvidersResult;
  setting: Setting;
  searchSupplementsV2: SearchSupplementV2Result;
  supplementsV2: Array<Maybe<SupplementV2>>;
  getRequisitionDocument?: Maybe<LabDocument>;
  getConsolidatedRequisitionDocument?: Maybe<LabDocument>;
  getAvailability: Array<PapiAvailabilitySet>;
  /**
   * Gets availability per provider, takes start and end date returns a array of availabilites
   *
   * If there is an authorization error this will return NOT FOUND
   */
  getAvailabilityForProvider: Array<PapiDailyAvailabilitySet>;
  getAppointmentsByStatus: Array<PapiScheduledAppointment>;
  getAppointmentsByPurchasePeriod: Array<PapiAppointment>;
  getAppointment?: Maybe<PapiAppointment>;
  getAppointmentsForPrep: Array<PapiScheduledAppointment>;
  /**
   * Based on the appointment group it will return the next possible appointment to schedule.
   *
   * If there is not appointment it will return null
   */
  getNextAppointment?: Maybe<PapiAppointment>;
  /** Here we return the availability sets using virtual format */
  getAllAvailability: PapiDailyAllAvailabilitySet;
  /**
   * Here we return the availability sets for all the formats, using the same logic as getAllAvailability
   * but including all the formats on the response
   */
  getAllAvailabilityByFormats: Array<PapiDailyAllAvailabilitySet>;
  getScheduledAppointmentsForProvider: Array<PapiScheduledAppointment>;
  getEarliestDateForProviders: Array<PapiNextAvailability>;
  listAppointmentSequence: Array<PapiAppointmentSequence>;
  listAppointmentTypes: Array<PapiAppointmentDetails>;
  listCenters: Array<PapiCenter>;
  listCoupons?: Maybe<PapiCouponConnection>;
  coupon?: Maybe<PapiCoupon>;
  validateCoupon?: Maybe<PapiCouponValidationResponse>;
  listPayers: Array<PapiInsurancePayer>;
  searchInsuranceCompany: Array<PapiInsurancePayer>;
  getRelationshipTypes: Array<PapiSubscriberRelationship>;
  getInsuranceSubscriberById?: Maybe<PapiInsuranceSubscriber>;
  getLatestEligibilityResponseForSubscriber?: Maybe<PapiRunEligibilityCheckResponse>;
  getInsuranceSubscribersByPersonId?: Maybe<Array<PapiInsuranceSubscriber>>;
  runEligibilityCheck: PapiRunEligibilityCheckResponse;
  runEligibilityCheckForPerson: PapiRunEligibilityCheckResponse;
  realtimeEligibilityCheck: PapiRealtimeEligibilityCheckResponse;
  realtimeEligibilityCheckForPerson: PapiRealtimeEligibilityCheckResponse;
  getMostRecentEligibilityCheckForSubscriber?: Maybe<PapiRealtimeEligibilityCheckResponse>;
  listLeadEligibilityCheckResponse: Array<PapiLeadEligibilityCheck>;
  getInsuranceSubscriberByPersonId?: Maybe<PapiInsuranceSubscriber>;
  listPayerCenters: Array<PapiInsurancePayerCenters>;
  listPaymentReceipts: Array<PapiPurchaseReceipt>;
  listInvoicesForPerson: Array<PapiInvoice>;
  listInvoicesForCurrentPerson: Array<PapiInvoice>;
  getAgreementsForPlan: Array<PapiLegalAgreement>;
  getUnsignedAgreementsForPerson: Array<PapiLegalAgreement>;
  getPersonEmergencyContact?: Maybe<PapiPersonEmergencyContact>;
  emailValid: Scalars['Boolean']['output'];
  getPerson?: Maybe<PapiPerson>;
  currentPapiPerson: PapiPerson;
  searchPersons: Array<PapiPerson>;
  resetPassword: Scalars['Boolean']['output'];
  getTimeline: Array<PapiTimeline>;
  /** Create custom token from firestore */
  getFirestoreToken?: Maybe<Scalars['String']['output']>;
  /** Create custom token from user database */
  getCustomAuthToken?: Maybe<Scalars['String']['output']>;
  getIsMemberEligibleForInsurance: Scalars['Boolean']['output'];
  getIsMemberEligibleForInsuranceFlow: PapiPreEligibilityStatus;
  getEmail?: Maybe<Scalars['String']['output']>;
  createCustomToken?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<PapiPlan>;
  listAvailableInsuranceChanges: Array<PapiPlan>;
  listAllPlans?: Maybe<PapiPlanConnection>;
  listPlansForProductType: Array<PapiPlan>;
  listRegisteredProducts: Array<PapiProduct>;
  listRenewalPlans?: Maybe<Array<Maybe<PapiProduct>>>;
  listRenewalPlansFromRPC?: Maybe<Array<PapiPlan>>;
  listUpgradePlans?: Maybe<Array<Maybe<PapiProduct>>>;
  listNewMemberPlans: Array<PapiNewMemberPlan>;
  product?: Maybe<PapiProduct>;
  getQuote?: Maybe<PapiQuote>;
  getAvailabilities: Array<PapiAvailabititiesResponse>;
  getAvailableInsuranceChangesForPerson?: Maybe<PapiPlan>;
  getAllAvailabilities: Array<PapiAllAvailabititiesResponse>;
  getAllPlansWithCenters: Array<PapiAllPlansWithCentersResponse>;
  getStateForZipCode?: Maybe<PapiUsState>;
  onFreeTrial: Scalars['Boolean']['output'];
  paidLatestInvoice: PapiPaidLatestInvoiceStatus;
  validatePurchase: Scalars['Boolean']['output'];
  /** @deprecated We no longer use this for free trial */
  getStripeSubscription?: Maybe<PapiStripeSubscription>;
  providerProfile?: Maybe<PapiProviderProfile>;
  providerProfileByHelpScoutID?: Maybe<PapiProviderProfile>;
  getMatchingProvider: Array<PapiProvider>;
  getMostSuitableProvider: Array<PapiProvider>;
  getProvider?: Maybe<PapiProvider>;
  getProviderByMDHQID?: Maybe<PapiProvider>;
  getProviderByHelpScoutID?: Maybe<PapiProvider>;
  listProviders: Array<PapiProvider>;
  listProvidersByType: Array<PapiProvider>;
  listProvidersByPerson: Array<PapiProvider>;
  listProvidersByEmploymentType: Array<PapiProvider>;
  getQuestionnairesForAppointment: Array<PapiQuestionnaire>;
  questionnaireByID?: Maybe<PapiQuestionnaire>;
  questionnairesByStatus: Array<PapiQuestionnaire>;
  /**
   * Gets questionnaires and additionally filters them by the given
   * input criteria, useful for the new questionnaires product page.
   */
  questionnairesByFilter: Array<PapiQuestionnaire>;
  intakeFormQuestionnaires: Array<PapiQuestionnaire>;
  getCancellationOptions?: Maybe<Array<Maybe<SanityCancellationOptions>>>;
  getSlug?: Maybe<Array<Maybe<SanitySlugItem>>>;
  getVisitInfoPage?: Maybe<Array<Maybe<SanityVisitInfoPage>>>;
  conversations: Array<Conversation>;
  conversationsWithPagination: ConversationsResult;
  conversation: Conversation;
  healthTopics: Array<MessageTopic>;
  membershipTopics: Array<MessageTopic>;
  getImawarePatient: ImawarePatient;
  visitNote?: Maybe<VisitNote>;
  visitNotes: Array<VisitNote>;
  document?: Maybe<MdhqDocument>;
  documents: Array<MdhqDocument>;
  documentsByFolders: Array<MdhqDocument>;
  medications: Array<Medication>;
  supplements: Array<Supplement>;
  searchMedications: Array<Medication>;
  searchSupplements: Array<Supplement>;
  currentPerson: Person;
  getPersonV2: Person;
  questionnaireConfigCache: QuestionnaireConfigCache;
  questionnaireSetting: QuestionnaireSetting;
};


export type QueryValidatePasswordSecurityArgs = {
  password: Scalars['String']['input'];
};


export type QueryGetPartnerEmployeeArgs = {
  filters: EmployeeFilter;
};


export type QuerySendAuthLinkArgs = {
  request: SendAuthLinkRequest;
};


export type QueryGetPartnerArgs = {
  slug: Scalars['String']['input'];
};


export type QueryGetPartnersArgs = {
  filters: PartnerFilter;
};


export type QueryGetPartnerPlansArgs = {
  slug: Scalars['String']['input'];
};


export type QueryGetPendingPartnerEmployeesArgs = {
  request: GetPendingPartnerEmployeeRequest;
};


export type QueryConversationsV2Args = {
  filters: ConversationsFilterV2;
};


export type QueryConversationV2Args = {
  conversationID: Scalars['ID']['input'];
};


export type QueryConversationTopicsArgs = {
  topic?: InputMaybe<Scalars['String']['input']>;
  supportGroup?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDocumentV2Args = {
  id: Scalars['ID']['input'];
  object?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDocumentsV2Args = {
  patientID: Scalars['ID']['input'];
  category: Scalars['String']['input'];
  filter?: InputMaybe<DocumentV2Filter>;
};


export type QueryDocumentsByFoldersV2Args = {
  patientID: Scalars['ID']['input'];
  folders: Array<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentV2Filter>;
};


export type QuerySearchDrugsV2Args = {
  filters?: InputMaybe<DrugV2Filter>;
};


export type QueryDrugV2Args = {
  drugID: Scalars['String']['input'];
};


export type QueryVisitNoteV2Args = {
  id: Scalars['ID']['input'];
};


export type QueryMedicationV2Args = {
  medicationID: Scalars['String']['input'];
};


export type QueryMessagingMigrationTrackingArgs = {
  filters?: InputMaybe<MigrationFilter>;
};


export type QueryEncounterMigrationTrackingArgs = {
  filters?: InputMaybe<MigrationFilter>;
};


export type QueryDocumentMigrationTrackingArgs = {
  filters?: InputMaybe<MigrationFilter>;
};


export type QueryPatientV2Args = {
  patientID: Scalars['ID']['input'];
};


export type QueryAdtV2Args = {
  emrID: Scalars['String']['input'];
};


export type QueryPatientTagsV2Args = {
  patientID: Scalars['ID']['input'];
  filter?: InputMaybe<PatientTagV2Filter>;
};


export type QueryEmrProviderArgs = {
  emrProviderID: Scalars['ID']['input'];
};


export type QueryEmrProvidersArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EmrProvidersFilter>;
};


export type QuerySettingArgs = {
  key: Scalars['String']['input'];
};


export type QuerySearchSupplementsV2Args = {
  filters?: InputMaybe<SupplementV2Filter>;
};


export type QueryGetRequisitionDocumentArgs = {
  labOrderItemID: Scalars['ID']['input'];
};


export type QueryGetConsolidatedRequisitionDocumentArgs = {
  labOrderID: Scalars['ID']['input'];
};


export type QueryGetAvailabilityArgs = {
  providerIDs: Array<Scalars['ID']['input']>;
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
  appointmentID: Scalars['ID']['input'];
};


export type QueryGetAvailabilityForProviderArgs = {
  providerID: Scalars['ID']['input'];
  startTime: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  appointmentID: Scalars['ID']['input'];
  zone: Scalars['String']['input'];
};


export type QueryGetAppointmentsByStatusArgs = {
  minTime: Scalars['String']['input'];
  maxTime: Scalars['String']['input'];
  centerIds: Array<Scalars['ID']['input']>;
  statuses: Array<PapiAppointmentStatus>;
};


export type QueryGetAppointmentsByPurchasePeriodArgs = {
  personID: Scalars['ID']['input'];
  purchasePeriodID: Scalars['ID']['input'];
};


export type QueryGetAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAppointmentsForPrepArgs = {
  minTime: Scalars['String']['input'];
  maxTime: Scalars['String']['input'];
  groups: Array<PapiAppointmentGroup>;
  providerID?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetNextAppointmentArgs = {
  group: PapiAppointmentGroup;
};


export type QueryGetAllAvailabilityArgs = {
  startTime: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  appointmentID: Scalars['ID']['input'];
  zone: Scalars['String']['input'];
  providers: Array<Scalars['ID']['input']>;
};


export type QueryGetAllAvailabilityByFormatsArgs = {
  startTime: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  appointmentID: Scalars['ID']['input'];
  zone: Scalars['String']['input'];
  providers: Array<Scalars['ID']['input']>;
  formats?: InputMaybe<Array<PapiAppointmentFormat>>;
};


export type QueryGetScheduledAppointmentsForProviderArgs = {
  minTime: Scalars['String']['input'];
  maxTime: Scalars['String']['input'];
  providerID: Scalars['ID']['input'];
};


export type QueryGetEarliestDateForProvidersArgs = {
  appointmentID: Scalars['ID']['input'];
  providerIDs: Array<Scalars['ID']['input']>;
  formats?: InputMaybe<Array<PapiAppointmentFormat>>;
};


export type QueryListAppointmentSequenceArgs = {
  sequenceID: Scalars['ID']['input'];
};


export type QueryListAppointmentTypesArgs = {
  productType: PapiProductType;
};


export type QueryListCouponsArgs = {
  centerID?: InputMaybe<Scalars['ID']['input']>;
  onlyRedeemable: Scalars['Boolean']['input'];
  planID?: InputMaybe<Scalars['ID']['input']>;
  productType?: InputMaybe<PapiProductType>;
};


export type QueryCouponArgs = {
  slug: Scalars['String']['input'];
};


export type QueryValidateCouponArgs = {
  planId: Scalars['String']['input'];
  centerId: Scalars['String']['input'];
  couponSlug: Scalars['String']['input'];
};


export type QueryListPayersArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  nationalPayerID?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchInsuranceCompanyArgs = {
  search: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetInsuranceSubscriberByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetLatestEligibilityResponseForSubscriberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInsuranceSubscribersByPersonIdArgs = {
  personId: Scalars['ID']['input'];
};


export type QueryRunEligibilityCheckArgs = {
  input: PapiRunEligibilityCheckInput;
};


export type QueryRunEligibilityCheckForPersonArgs = {
  personId: Scalars['ID']['input'];
};


export type QueryRealtimeEligibilityCheckArgs = {
  input: PapiRealtimeEligibilityCheckInput;
};


export type QueryRealtimeEligibilityCheckForPersonArgs = {
  personId: Scalars['ID']['input'];
};


export type QueryGetMostRecentEligibilityCheckForSubscriberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListLeadEligibilityCheckResponseArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetInsuranceSubscriberByPersonIdArgs = {
  personId: Scalars['ID']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryListPayerCentersArgs = {
  filterAcceptedPayers: Scalars['Boolean']['input'];
};


export type QueryListPaymentReceiptsArgs = {
  personID: Scalars['ID']['input'];
};


export type QueryListInvoicesForPersonArgs = {
  personID: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  dueBefore?: InputMaybe<Scalars['String']['input']>;
  dueAfter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListInvoicesForCurrentPersonArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  dueBefore?: InputMaybe<Scalars['String']['input']>;
  dueAfter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAgreementsForPlanArgs = {
  planId: Scalars['ID']['input'];
};


export type QueryGetUnsignedAgreementsForPersonArgs = {
  personId: Scalars['ID']['input'];
};


export type QueryGetPersonEmergencyContactArgs = {
  personId: Scalars['ID']['input'];
};


export type QueryEmailValidArgs = {
  email: Scalars['String']['input'];
  recaptcha?: InputMaybe<PapiRecaptchaInput>;
};


export type QueryGetPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySearchPersonsArgs = {
  search: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetTimelineArgs = {
  includeHealthyHomework?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetIsMemberEligibleForInsuranceArgs = {
  personID: Scalars['ID']['input'];
};


export type QueryGetIsMemberEligibleForInsuranceFlowArgs = {
  personID: Scalars['ID']['input'];
};


export type QueryGetEmailArgs = {
  personID: Scalars['ID']['input'];
};


export type QueryCreateCustomTokenArgs = {
  personID: Scalars['ID']['input'];
};


export type QueryPlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListAvailableInsuranceChangesArgs = {
  planID: Scalars['ID']['input'];
};


export type QueryListPlansForProductTypeArgs = {
  productType: PapiProductType;
};


export type QueryListNewMemberPlansArgs = {
  couponSlug?: InputMaybe<Scalars['String']['input']>;
  productType?: InputMaybe<PapiProductType>;
  centerID?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductArgs = {
  type: PapiProductType;
};


export type QueryGetQuoteArgs = {
  planId: Scalars['String']['input'];
  centerId: Scalars['String']['input'];
  couponId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAvailabilitiesArgs = {
  zip: Scalars['String']['input'];
};


export type QueryGetAvailableInsuranceChangesForPersonArgs = {
  personID: Scalars['ID']['input'];
};


export type QueryGetStateForZipCodeArgs = {
  zip: Scalars['String']['input'];
};


export type QueryOnFreeTrialArgs = {
  purchaseID: Scalars['ID']['input'];
};


export type QueryPaidLatestInvoiceArgs = {
  purchaseID: Scalars['ID']['input'];
};


export type QueryValidatePurchaseArgs = {
  request: PapiOneTimePurchaseInput;
};


export type QueryGetStripeSubscriptionArgs = {
  purchaseID: Scalars['ID']['input'];
};


export type QueryProviderProfileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProviderProfileByHelpScoutIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetMatchingProviderArgs = {
  centerId: Scalars['ID']['input'];
  dob: Scalars['String']['input'];
  productType: PapiProductType;
};


export type QueryGetMostSuitableProviderArgs = {
  centerId: Scalars['ID']['input'];
  keywords: Array<Scalars['String']['input']>;
  productType: PapiProductType;
};


export type QueryGetProviderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetProviderByMdhqidArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetProviderByHelpScoutIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListProvidersByTypeArgs = {
  providerType: PapiProviderType;
};


export type QueryListProvidersByPersonArgs = {
  personId: Scalars['ID']['input'];
  providerTypes?: InputMaybe<Array<InputMaybe<PapiProviderType>>>;
};


export type QueryListProvidersByEmploymentTypeArgs = {
  employmentType: PapiEmploymentType;
};


export type QueryGetQuestionnairesForAppointmentArgs = {
  appointmentID: Scalars['ID']['input'];
  includeIntakeForms?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryQuestionnaireByIdArgs = {
  ID: Scalars['ID']['input'];
};


export type QueryQuestionnairesByStatusArgs = {
  statusList: Array<PapiQuestionnaireStatus>;
  onlyComplete?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryQuestionnairesByFilterArgs = {
  statusFilters: Array<PapiQuestionnarieStatusFilter>;
  typeFilters: Array<PapiQuestionnarieTypeFilter>;
  visitFilters: Array<PapiQuestionnarieVisitFilter>;
};


export type QueryGetCancellationOptionsArgs = {
  target: Scalars['String']['input'];
};


export type QueryGetSlugArgs = {
  slugName: Scalars['String']['input'];
};


export type QueryGetVisitInfoPageArgs = {
  targetVisitInfoPage: Scalars['String']['input'];
};


export type QueryConversationsArgs = {
  modifiedSince?: InputMaybe<Scalars['String']['input']>;
};


export type QueryConversationsWithPaginationArgs = {
  modifiedSince?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ConversationStatus>;
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
};


export type QueryConversationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetImawarePatientArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitNoteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitNotesArgs = {
  patientID: Scalars['ID']['input'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
  object?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDocumentsArgs = {
  patientID: Scalars['ID']['input'];
  category: Scalars['String']['input'];
};


export type QueryDocumentsByFoldersArgs = {
  patientID: Scalars['ID']['input'];
  folders: Array<Scalars['String']['input']>;
};


export type QueryMedicationsArgs = {
  patientID: Scalars['ID']['input'];
};


export type QuerySupplementsArgs = {
  patientID: Scalars['ID']['input'];
};


export type QuerySearchMedicationsArgs = {
  searchTerm: Scalars['String']['input'];
};


export type QuerySearchSupplementsArgs = {
  searchTerm: Scalars['String']['input'];
};


export type QueryGetPersonV2Args = {
  id: Scalars['ID']['input'];
};


export type QueryQuestionnaireConfigCacheArgs = {
  type: Scalars['String']['input'];
};


export type QueryQuestionnaireSettingArgs = {
  key: Scalars['String']['input'];
};

export type PasswordValidateResponse = {
  __typename?: 'PasswordValidateResponse';
  isSecure: Scalars['Boolean']['output'];
  errorCode?: Maybe<PasswordValidateErrorCode>;
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export enum PasswordValidateErrorCode {
  LENGTH_ERROR = 'LENGTH_ERROR',
  HAVE_BEEN_PWNED = 'HAVE_BEEN_PWNED'
}

export type EmployeeFilter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  personID?: InputMaybe<Scalars['ID']['input']>;
};

export type PartnerEmployee = {
  __typename?: 'PartnerEmployee';
  id: Scalars['ID']['output'];
  attributes?: Maybe<Array<PartnerEmployeeAttr>>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  preferredEmail: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  biologicalSex?: Maybe<BiologicalSex>;
  partner: Partner;
  createdAt: Scalars['Time']['output'];
  coverageStartAt?: Maybe<Scalars['Date']['output']>;
  coverageEndAt?: Maybe<Scalars['Date']['output']>;
  enrolledAt?: Maybe<Scalars['Date']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  registrationType: RegistrationType;
};


export type PartnerEmployeeAttributesArgs = {
  filter?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PartnerEmployeeAttr = {
  __typename?: 'PartnerEmployeeAttr';
  attr: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum BiologicalSex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export type Partner = {
  __typename?: 'Partner';
  id: Scalars['ID']['output'];
  attributes?: Maybe<Array<PartnerAttr>>;
  clinicalPod?: Maybe<Array<PartnerPod>>;
  employeeGroups?: Maybe<Array<Partner>>;
  displayName?: Maybe<Scalars['String']['output']>;
  launchDate?: Maybe<Scalars['Date']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Partner>;
  ruleset?: Maybe<Ruleset>;
  slug: Scalars['String']['output'];
  status?: Maybe<PartnerStatus>;
  createdAt: Scalars['Time']['output'];
};


export type PartnerAttributesArgs = {
  filter?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PartnerAttr = {
  __typename?: 'PartnerAttr';
  attr: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type PartnerPod = {
  __typename?: 'PartnerPod';
  partnerID: Scalars['ID']['output'];
  podID: Scalars['ID']['output'];
};

export type Ruleset = {
  __typename?: 'Ruleset';
  id: Scalars['ID']['output'];
  pageCount: Scalars['Int']['output'];
  questions: Array<Question>;
  questionsByPage: Array<Question>;
  rules?: Maybe<Scalars['JSON']['output']>;
};


export type RulesetQuestionsByPageArgs = {
  page: Scalars['Int']['input'];
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID']['output'];
  displayOrder: Scalars['Int']['output'];
  displayPage: Scalars['Int']['output'];
  inputProps: Scalars['JSON']['output'];
  inputType: QuestionInputType;
  options?: Maybe<Array<Option>>;
  question: Scalars['String']['output'];
  questionType: QuestionType;
};

export enum QuestionInputType {
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  TEXTAREA = 'TEXTAREA'
}

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum QuestionType {
  PROGRAM = 'PROGRAM'
}

export enum PartnerStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum RegistrationType {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PARTNER_PORTAL = 'PARTNER_PORTAL',
  DR_PARSLEY = 'DR_PARSLEY'
}

export type SendAuthLinkRequest = {
  partnerSlug: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type SendAuthLinkResponse = {
  __typename?: 'SendAuthLinkResponse';
  status: AuthLinkStatus;
};

export enum AuthLinkStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export type PartnerFilter = {
  parentID?: InputMaybe<Scalars['ID']['input']>;
  pagination?: InputMaybe<PaginationRequest>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  type: PartnerSearchType;
};

export type PaginationRequest = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum PartnerSearchType {
  PARTNER = 'PARTNER',
  GROUP = 'GROUP'
}

export type GetPartnersResult = {
  __typename?: 'GetPartnersResult';
  pagination: PaginationResult;
  result?: Maybe<Array<Partner>>;
};

export type PaginationResult = {
  __typename?: 'PaginationResult';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['String']['output']>;
};

export type PartnerPlan = {
  __typename?: 'PartnerPlan';
  partnerID: Scalars['ID']['output'];
  planID: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type GetPendingPartnerEmployeeRequest = {
  pagination?: InputMaybe<PaginationRequest>;
  partnerSlug: Scalars['String']['input'];
};

export type GetPendingPartnerEmployeesResponse = {
  __typename?: 'GetPendingPartnerEmployeesResponse';
  fileName: Scalars['String']['output'];
  pagination: PaginationResult;
  records?: Maybe<Array<PendingPartnerEmployee>>;
  status: EligibilityMigrationStatus;
  receivedAt: Scalars['Time']['output'];
};

export type PendingPartnerEmployee = {
  __typename?: 'PendingPartnerEmployee';
  id: Scalars['ID']['output'];
  attributes?: Maybe<Array<PendingPartnerEmployeeAttr>>;
  action?: Maybe<PendingPartnerEmployeeAction>;
  addressState?: Maybe<Scalars['String']['output']>;
  addressZipCode?: Maybe<Scalars['String']['output']>;
  biologicalSex?: Maybe<Scalars['String']['output']>;
  coverageEndAt?: Maybe<Scalars['Date']['output']>;
  coverageStartAt?: Maybe<Scalars['Date']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  errorMessages: Array<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};


export type PendingPartnerEmployeeAttributesArgs = {
  filter?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PendingPartnerEmployeeAttr = {
  __typename?: 'PendingPartnerEmployeeAttr';
  attr: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum PendingPartnerEmployeeAction {
  ERROR = 'ERROR',
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export enum EligibilityMigrationStatus {
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  STAGED = 'STAGED',
  COMMITTING = 'COMMITTING',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED'
}

export type ConversationsFilterV2 = {
  status: ConversationStatusV2;
  readStatus?: InputMaybe<ReadStatus>;
  pagination?: InputMaybe<PaginationRequest>;
};

export enum ConversationStatusV2 {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export enum ReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export type ConversationsResultV2 = {
  __typename?: 'ConversationsResultV2';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['Cursor']['output']>;
  records: Array<ConversationV2>;
};

export type ConversationV2 = {
  __typename?: 'ConversationV2';
  emrID: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  conversationMembershipID?: Maybe<Scalars['String']['output']>;
  ownerID: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Time']['output']>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
  isUrgent: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  assignedProvider: ConversationCreator;
  subject: Scalars['String']['output'];
  messages: Array<ConversationMessageV2>;
  status: ConversationStatusV2;
  topicType: Scalars['String']['output'];
  lastMessage?: Maybe<ConversationMessageV2>;
};

export type ConversationCreator = {
  __typename?: 'ConversationCreator';
  id: Scalars['String']['output'];
  emrID: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  photoUrl?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ConversationCreatorType>;
};

export enum ConversationCreatorType {
  PATIENT = 'PATIENT',
  PROVIDER = 'PROVIDER'
}

export type ConversationMessageV2 = {
  __typename?: 'ConversationMessageV2';
  id: Scalars['String']['output'];
  emrID: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userID: Scalars['String']['output'];
  creator: ConversationCreator;
  isRead: Scalars['Boolean']['output'];
};

export type ConversationTopic = {
  __typename?: 'ConversationTopic';
  id: Scalars['ID']['output'];
  topic: Scalars['String']['output'];
  type: Scalars['String']['output'];
  is_clinical: Scalars['Boolean']['output'];
  support_group?: Maybe<Scalars['String']['output']>;
  coordinators: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
};

export type DocumentV2 = {
  __typename?: 'DocumentV2';
  reviewedBy?: Maybe<Provider>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notifications?: Maybe<Array<Maybe<NotificationV2>>>;
  reviewedAt?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  folder: Scalars['String']['output'];
  subfolder?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  content: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  object: Scalars['String']['output'];
  emrPatientID?: Maybe<Scalars['String']['output']>;
};

export type Provider = {
  __typename?: 'Provider';
  object?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  npi?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  isResource?: Maybe<Scalars['Boolean']['output']>;
  hasCalendar?: Maybe<Scalars['Boolean']['output']>;
  userNotes?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  displayNameForMessages?: Maybe<Scalars['String']['output']>;
  displayNameForAppointments?: Maybe<Scalars['String']['output']>;
  created: Scalars['String']['output'];
  phoneHome?: Maybe<Scalars['String']['output']>;
};

export type NotificationV2 = {
  __typename?: 'NotificationV2';
  id: Scalars['ID']['output'];
  body: Scalars['String']['output'];
};

export type DocumentV2Filter = {
  pagination?: InputMaybe<PaginationRequest>;
};

export type DrugV2Filter = {
  searchString: Scalars['String']['input'];
  pagination?: InputMaybe<PaginationRequest>;
};

export type DrugV2Result = {
  __typename?: 'DrugV2Result';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['String']['output']>;
  records: Array<DrugV2>;
};

export type DrugV2 = {
  __typename?: 'DrugV2';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  genericName: Scalars['String']['output'];
  doseForm: Scalars['String']['output'];
  dispenseUnit?: Maybe<Scalars['String']['output']>;
  packageForm?: Maybe<Scalars['String']['output']>;
  vaId?: Maybe<Scalars['String']['output']>;
  medispanId?: Maybe<Scalars['String']['output']>;
  ndcndfId?: Maybe<Scalars['String']['output']>;
  classIdentifier?: Maybe<Scalars['String']['output']>;
  defaultStrength?: Maybe<Scalars['String']['output']>;
  defaultUnits?: Maybe<Scalars['String']['output']>;
  isOtc: Scalars['Boolean']['output'];
  controlLevel?: Maybe<Scalars['Int']['output']>;
  created: Scalars['String']['output'];
};

export type VisitNoteV2 = {
  __typename?: 'VisitNoteV2';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  encounterType: EncounterTypeV2;
  encounterTypeName?: Maybe<Scalars['String']['output']>;
  dateOfService: Scalars['String']['output'];
  createdBy?: Maybe<Provider>;
  createdAt?: Maybe<Scalars['String']['output']>;
  soapSections?: Maybe<Array<SoapObjectV2>>;
  soapPlans?: Maybe<Array<SoapObjectV2>>;
  isLegacy?: Maybe<Scalars['Boolean']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  signedBy?: Maybe<Provider>;
};

export type EncounterTypeV2 = {
  __typename?: 'EncounterTypeV2';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SoapObjectV2 = {
  __typename?: 'SoapObjectV2';
  id: Scalars['ID']['output'];
  heading?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
};

export type MedicationV2 = {
  __typename?: 'MedicationV2';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  genericName?: Maybe<Scalars['String']['output']>;
  strength?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  origin: Origin;
  isPending: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Time']['output']>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
  startAt?: Maybe<Scalars['Time']['output']>;
  expiresAt?: Maybe<Scalars['Time']['output']>;
  discontinuedAt?: Maybe<Scalars['Time']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  isAdminRecord?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  drugDetailsID?: Maybe<Scalars['String']['output']>;
  dateExpired?: Maybe<Scalars['String']['output']>;
};

export enum Origin {
  PH_PRESCRIBED = 'PH_PRESCRIBED',
  SELF_REPORTED = 'SELF_REPORTED'
}

export type MigrationFilter = {
  personIDs?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MigrationStatus>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export enum MigrationStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type MessagingMigrationResult = {
  __typename?: 'MessagingMigrationResult';
  totalCount: Scalars['Int']['output'];
  records: Array<Maybe<MessagingMigration>>;
};

export type MessagingMigration = {
  __typename?: 'MessagingMigration';
  id: Scalars['String']['output'];
  personID: Scalars['String']['output'];
  healthiePatientID: Scalars['String']['output'];
  helpScoutPatientID: Scalars['String']['output'];
  status: MigrationStatus;
  errorMessage?: Maybe<Scalars['String']['output']>;
  totalMessagesCount?: Maybe<Scalars['Int']['output']>;
  activeConversationsCount?: Maybe<Scalars['Int']['output']>;
  closedConversationsCount?: Maybe<Scalars['Int']['output']>;
  skippedMessagesCount?: Maybe<Scalars['Int']['output']>;
  skippedActiveConversationsCount?: Maybe<Scalars['Int']['output']>;
  skippedClosedConversationsCount?: Maybe<Scalars['Int']['output']>;
  retryCount?: Maybe<Scalars['Int']['output']>;
};

export type EncounterMigrationResult = {
  __typename?: 'EncounterMigrationResult';
  totalCount: Scalars['Int']['output'];
  records: Array<Maybe<EncounterMigration>>;
};

export type EncounterMigration = {
  __typename?: 'EncounterMigration';
  id: Scalars['String']['output'];
  personID: Scalars['String']['output'];
  healthiePatientID: Scalars['String']['output'];
  status: MigrationStatus;
  errorMessage?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DocumentMigrationResult = {
  __typename?: 'DocumentMigrationResult';
  totalCount: Scalars['Int']['output'];
  records: Array<Maybe<DocumentMigration>>;
};

export type DocumentMigration = {
  __typename?: 'DocumentMigration';
  id: Scalars['String']['output'];
  personID: Scalars['String']['output'];
  healthiePatientID: Scalars['String']['output'];
  status: MigrationStatus;
  errorMessage?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientV2 = {
  __typename?: 'PatientV2';
  id: Scalars['ID']['output'];
  emrID: Scalars['String']['output'];
  stripeApiId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  inactive: Scalars['Boolean']['output'];
  portalAccess: Scalars['Boolean']['output'];
  dob: Scalars['String']['output'];
  hintId?: Maybe<Scalars['String']['output']>;
  email1: Scalars['String']['output'];
  sex: Scalars['String']['output'];
  phoneHome?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  insuranceType?: Maybe<Scalars['String']['output']>;
};

export type Adtv2 = {
  __typename?: 'ADTV2';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  insurancePlan: Scalars['String']['output'];
  sufficientInsuranceDetails: Scalars['Boolean']['output'];
  enterhealthAccountToQuery?: Maybe<Scalars['String']['output']>;
  warnings: Array<Scalars['String']['output']>;
};

export type PatientTagV2Filter = {
  pagination?: InputMaybe<PaginationRequest>;
};

export type PatientTagV2Result = {
  __typename?: 'PatientTagV2Result';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['Cursor']['output']>;
  records: Array<PatientTagV2>;
};

export type PatientTagV2 = {
  __typename?: 'PatientTagV2';
  name: Scalars['String']['output'];
  tagCategory: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  dateApplied: Scalars['String']['output'];
};

export type EmrProvidersFilter = {
  pagination?: InputMaybe<PaginationRequest>;
};

export type EmrProvidersResult = {
  __typename?: 'EmrProvidersResult';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['Cursor']['output']>;
  records: Array<Provider>;
};

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['ID']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type SupplementV2Filter = {
  searchString: Scalars['String']['input'];
  pagination?: InputMaybe<PaginationRequest>;
};

export type SearchSupplementV2Result = {
  __typename?: 'SearchSupplementV2Result';
  result: Array<SearchSupplementV2>;
  pagination: PaginationResult;
};

export type SearchSupplementV2 = {
  __typename?: 'SearchSupplementV2';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nickNames?: Maybe<Scalars['String']['output']>;
  externalRefID?: Maybe<Scalars['String']['output']>;
  class?: Maybe<Scalars['String']['output']>;
  inactive?: Maybe<Scalars['Boolean']['output']>;
  vendorCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
};

export type SupplementV2 = {
  __typename?: 'SupplementV2';
  id: Scalars['ID']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  strength?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  isPending: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Time']['output']>;
  startAt?: Maybe<Scalars['Time']['output']>;
  expiresAt?: Maybe<Scalars['Time']['output']>;
  discontinuedAt?: Maybe<Scalars['Time']['output']>;
  isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  isAdminRecord?: Maybe<Scalars['Boolean']['output']>;
};

export type LabDocument = {
  __typename?: 'LabDocument';
  mimetype: Scalars['String']['output'];
  content: Scalars['String']['output'];
};

export type PapiAvailabilitySet = {
  __typename?: 'PapiAvailabilitySet';
  providerID: Scalars['ID']['output'];
  appointmentID: Scalars['ID']['output'];
  category: PapiAppointmentCategory;
  format: PapiAppointmentFormat;
  availableSlots: Array<PapiAppointmentSlot>;
};

export enum PapiAppointmentCategory {
  MEDICAL_INITIAL = 'MEDICAL_INITIAL',
  MEDICAL_FIRST_FOLLOW_UP = 'MEDICAL_FIRST_FOLLOW_UP',
  MEDICAL_FOLLOW_UP = 'MEDICAL_FOLLOW_UP',
  COACHING_INITIAL = 'COACHING_INITIAL',
  COACHING_FOLLOW_UP = 'COACHING_FOLLOW_UP',
  LAB = 'LAB',
  LAB_EXTERNAL = 'LAB_EXTERNAL',
  CONSULTATION = 'CONSULTATION',
  NON_STANDARD = 'NON_STANDARD',
  MEDICAL_ASSESSMENT = 'MEDICAL_ASSESSMENT',
  COACHING_CHECK_IN = 'COACHING_CHECK_IN',
  CARE_MANAGER_CHECK_IN = 'CARE_MANAGER_CHECK_IN',
  COACHING_INITIAL_PROGRAM = 'COACHING_INITIAL_PROGRAM',
  MEDICAL_INITIAL_PROGRAM = 'MEDICAL_INITIAL_PROGRAM',
  MEDICAL_TRANSFER_OF_CARE = 'MEDICAL_TRANSFER_OF_CARE',
  COACHING_TRANSFER_OF_CARE = 'COACHING_TRANSFER_OF_CARE'
}

export enum PapiAppointmentFormat {
  IN_PERSON = 'IN_PERSON',
  VIRTUAL = 'VIRTUAL',
  FORMAT_UNSPECIFIED = 'FORMAT_UNSPECIFIED'
}

export type PapiAppointmentSlot = {
  __typename?: 'PapiAppointmentSlot';
  slotID: Scalars['ID']['output'];
  start: Scalars['String']['output'];
  end: Scalars['String']['output'];
  providerAppointmentTypeID: Scalars['ID']['output'];
};

export type PapiDailyAvailabilitySet = {
  __typename?: 'PapiDailyAvailabilitySet';
  providerID: Scalars['ID']['output'];
  appointmentID: Scalars['ID']['output'];
  startTime: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  /** The visit category. Medical Initial etc. */
  dailyAvailability: Array<PapiDayAvailability>;
  /** The format of the visit either Virtual or In Person */
  format: PapiAppointmentFormat;
};

export type PapiDayAvailability = {
  __typename?: 'PapiDayAvailability';
  /** Date: Is the day the the availability belongs to. */
  date: Scalars['String']['output'];
  /** An array of availability slots. Is a single days of availability */
  availability: Array<PapiAppointmentSlot>;
};

export enum PapiAppointmentStatus {
  NO_SHOW = 'NO_SHOW',
  RESCHEDULED = 'RESCHEDULED',
  SCHEDULED = 'SCHEDULED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
  STATUS_UNSPECIFIED = 'STATUS_UNSPECIFIED',
  UNSCHEDULED = 'UNSCHEDULED'
}

export type PapiScheduledAppointment = {
  __typename?: 'PapiScheduledAppointment';
  id: Scalars['ID']['output'];
  paymentCostInCents: Scalars['Int']['output'];
  /** Possibly not unique Acuity Appointment ID */
  acuityID?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `availableProvidersForNewMember` instead */
  availableProviders: Array<PapiAppointmentProvider>;
  /** Get the list of Providers that a new member can with schedule for this appointment. */
  availableProvidersForNewMember: Array<PapiAppointmentProvider>;
  /**
   * Get the provider and next available slot for the given provider. Returns null
   * if the provider does not serve this visit type. Can optionally take a formats
   * array as input to be used as filter option.
   */
  nextAvailabilityForProvider?: Maybe<PapiAppointmentProvider>;
  /** @deprecated Use details */
  category?: Maybe<PapiAppointmentCategory>;
  /**
   * Type of appointment, e.g. Medical Initial, Coaching, Lab, and related details
   * @deprecated Use details
   */
  categoryDetails?: Maybe<PapiAppointmentCategoryDetails>;
  /** DAPI Center where the provider is servicing */
  center?: Maybe<PapiCenter>;
  centerID: Scalars['ID']['output'];
  completedAt?: Maybe<Scalars['String']['output']>;
  /** display order of the appointment for sequence */
  displayOrder: Scalars['Int']['output'];
  /** duration in minutes of the appointment */
  duration: Scalars['Int']['output'];
  /** UTC epoch time of the end of the appointment */
  endTime: Scalars['String']['output'];
  /** Online or in-person format of appointment */
  format: PapiAppointmentFormat;
  /** Is this appointment free? If true, does not count toward allotted appointments for PlanPurchase */
  isComplimentary: Scalars['Boolean']['output'];
  /** Optional notes associated with the appointment */
  metadata?: Maybe<Scalars['String']['output']>;
  /** DAPI Person for the member having this appointment */
  person?: Maybe<PapiPerson>;
  personID: Scalars['ID']['output'];
  /** DAPI Provider servicing this appointment */
  provider: PapiProvider;
  providerID: Scalars['ID']['output'];
  purchasePeriodID?: Maybe<Scalars['ID']['output']>;
  planPurchase?: Maybe<PapiPlanPurchase>;
  /** List of questionnaires related to this appointment */
  questionnaires: Array<PapiQuestionnaire>;
  /** Lowest status of questionnaires related to this appointment, e.g. started, accepted */
  questionnairesStatus?: Maybe<PapiQuestionnaireStatus>;
  /** UTC epoch time of the start of the appointment */
  startTime: Scalars['String']['output'];
  /** Scheduling status, e.g. scheduled, rescheduled, canceled */
  status: PapiAppointmentStatus;
  /** Optional location of visit, e.g. Zoom link */
  location?: Maybe<Scalars['String']['output']>;
  /** object with metadata information about an appointment */
  details: PapiAppointmentDetails;
  /** The relative URL for scheduling this appointment in My Parsley */
  portalScheduleURL: Scalars['String']['output'];
  /** Person who scheduled the appointment. If scheduled from acuity this will be null. */
  scheduledBy?: Maybe<PapiPerson>;
  /** Last updated time */
  lastUpdated?: Maybe<Scalars['String']['output']>;
  /** Visit Note id. If there's no visit note this will be null. */
  visitNoteID?: Maybe<Scalars['ID']['output']>;
  /**
   * This is the bounds that this appointment can be scheduled. It is bound by the
   * plan end time, with extensions or free trial with extensions
   */
  schedulablePeriod?: Maybe<PapiSchedulablePeriod>;
  availableWindows: Array<PapiTimeWindow>;
  encounterID?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
};


export type PapiScheduledAppointmentNextAvailabilityForProviderArgs = {
  providerId: Scalars['ID']['input'];
  formats?: InputMaybe<Array<PapiAppointmentFormat>>;
};

export type PapiAppointmentProvider = {
  __typename?: 'PapiAppointmentProvider';
  provider: PapiProvider;
  nextAvailableSlot?: Maybe<PapiAppointmentSlot>;
};

export type PapiProvider = {
  __typename?: 'PapiProvider';
  id: Scalars['ID']['output'];
  /**
   * ID required to fetch takeShape profile for provider
   * @deprecated Use Sanity
   */
  takeShapeID?: Maybe<Scalars['ID']['output']>;
  /** ID required to fetch sanity profile for provider */
  sanityID?: Maybe<Scalars['ID']['output']>;
  acuityID?: Maybe<Scalars['ID']['output']>;
  helpScoutID?: Maybe<Scalars['ID']['output']>;
  mdhqId?: Maybe<Scalars['String']['output']>;
  type: PapiProviderType;
  employmentType: PapiEmploymentType;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  professionalSuffix?: Maybe<PapiProfessionalSuffix>;
  photoURL?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  biologicalSex: PapiSex;
  centers: Array<PapiProviderCenter>;
  payerCredentials: Array<PapiProviderPayerCredentials>;
  acuityInstance: PapiAcuityInstance;
  appointmentTypes: Array<PapiProviderAppointmentType>;
  appointments: Array<PapiScheduledAppointment>;
  appointmentsForCalendar: Array<PapiAppointmentForCalendarView>;
  productTypes: Array<PapiProductType>;
  products: Array<PapiProduct>;
  targetPanelSize?: Maybe<Scalars['Int']['output']>;
  currentPanelSize?: Maybe<Scalars['Int']['output']>;
  timezone: Scalars['String']['output'];
  isTakingNewPatients: Scalars['Boolean']['output'];
  /** Assigned care manager for a clinician. */
  assignedCareManager?: Maybe<PapiProvider>;
  pod?: Maybe<PapiPod>;
  isMedicareOptedOut: Scalars['Boolean']['output'];
  attributeValue?: Maybe<Scalars['String']['output']>;
  attributes?: Maybe<Array<PapiProviderAttr>>;
  sanityProfile?: Maybe<SanityPerson>;
};


export type PapiProviderAppointmentsArgs = {
  minTime: Scalars['String']['input'];
  maxTime: Scalars['String']['input'];
};


export type PapiProviderAppointmentsForCalendarArgs = {
  minTime: Scalars['String']['input'];
  maxTime: Scalars['String']['input'];
  centerID?: InputMaybe<Scalars['ID']['input']>;
  productType?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
};


export type PapiProviderAttributeValueArgs = {
  type: PapiProviderAttrType;
};


export type PapiProviderAttributesArgs = {
  filter?: InputMaybe<PapiProviderAttributeFilter>;
};

export enum PapiProviderType {
  DOCTOR = 'DOCTOR',
  NURSE = 'NURSE',
  PHLEBOTOMIST = 'PHLEBOTOMIST',
  HEALTH_COACH = 'HEALTH_COACH',
  CARE_MANAGER = 'CARE_MANAGER',
  PEDIATRICIAN = 'PEDIATRICIAN',
  MXA = 'MXA',
  MX_PROVIDER_GROUP = 'MX_PROVIDER_GROUP',
  CARE_MANAGER_PROVIDER_GROUP = 'CARE_MANAGER_PROVIDER_GROUP',
  MEMBER_ADVOCATE = 'MEMBER_ADVOCATE',
  CLINICAL_COORDINATOR = 'CLINICAL_COORDINATOR'
}

export enum PapiEmploymentType {
  FULL_TIME = 'FULL_TIME',
  CONTRACTOR = 'CONTRACTOR'
}

export enum PapiProfessionalSuffix {
  MD = 'MD',
  DO = 'DO',
  RN = 'RN',
  NP = 'NP',
  PHD = 'PHD',
  MPH = 'MPH',
  PAC = 'PAC',
  DNP = 'DNP'
}

export enum PapiSex {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER'
}

export type PapiProviderCenter = {
  __typename?: 'PapiProviderCenter';
  center: PapiCenter;
  takingNewPatients: Scalars['Boolean']['output'];
};

export type PapiCenter = {
  __typename?: 'PapiCenter';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  state: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  mdhqHost?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  /** List of Providers at Center */
  providers: Array<PapiProvider>;
  isVirtual: Scalars['Boolean']['output'];
  abbreviatedName: Scalars['String']['output'];
};

export type PapiProviderPayerCredentials = {
  __typename?: 'PapiProviderPayerCredentials';
  payerID: Scalars['ID']['output'];
  centerID: Scalars['ID']['output'];
};

export enum PapiAcuityInstance {
  ACUITY_NYC = 'ACUITY_NYC',
  ACUITY_LA = 'ACUITY_LA',
  ACUITY_SF = 'ACUITY_SF'
}

export type PapiProviderAppointmentType = {
  __typename?: 'PapiProviderAppointmentType';
  id: Scalars['ID']['output'];
  providerID: Scalars['ID']['output'];
  category: PapiAppointmentCategory;
  format: PapiAppointmentFormat;
  duration: Scalars['Int']['output'];
  appointmentTypeID: Scalars['Int']['output'];
};

export type PapiAppointmentForCalendarView = {
  __typename?: 'PapiAppointmentForCalendarView';
  id: Scalars['ID']['output'];
  /** UTC epoch time of the end of the appointment */
  endTime: Scalars['String']['output'];
  format: PapiAppointmentFormat;
  patientName: Scalars['String']['output'];
  patientID: Scalars['ID']['output'];
  productAbbreviation?: Maybe<Scalars['String']['output']>;
  /** UTC epoch time of the start of the appointment */
  startTime: Scalars['String']['output'];
  displayColor?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
};

export enum PapiProductType {
  COMPLETE_CARE_Y1 = 'COMPLETE_CARE_Y1',
  COMPLETE_CARE_Y2_PLUS = 'COMPLETE_CARE_Y2_PLUS',
  COMPLETE_CARE_RENEWAL = 'COMPLETE_CARE_RENEWAL',
  ASSESSMENT = 'ASSESSMENT',
  ESSENTIAL_CARE = 'ESSENTIAL_CARE',
  PEDIATRICS = 'PEDIATRICS',
  PEDIATRICS_RENEWAL = 'PEDIATRICS_RENEWAL',
  FOUNDING_MEMBERSHIP = 'FOUNDING_MEMBERSHIP',
  WAITLIST_COMPLETE_CARE = 'WAITLIST_COMPLETE_CARE',
  WAITLIST_ASSESSMENT = 'WAITLIST_ASSESSMENT',
  WAITLIST_PEDIATRICS = 'WAITLIST_PEDIATRICS',
  EXTENSION = 'EXTENSION',
  FOLLOW_UP_ASSESSMENT = 'FOLLOW_UP_ASSESSMENT',
  MERIWETHER = 'MERIWETHER',
  COMPLETE_CARE_Y1_POST_ASSESSMENT = 'COMPLETE_CARE_Y1_POST_ASSESSMENT',
  WAITLIST_VIRTUAL_COMPLETE_CARE = 'WAITLIST_VIRTUAL_COMPLETE_CARE',
  VIRTUAL_COMPLETE_CARE_Y1 = 'VIRTUAL_COMPLETE_CARE_Y1',
  VIRTUAL_COMPLETE_CARE_Y2_PLUS = 'VIRTUAL_COMPLETE_CARE_Y2_PLUS',
  COMPREHENSIVE_HORMONE_PLAN = 'COMPREHENSIVE_HORMONE_PLAN',
  WAITLIST_VIRTUAL_ASSESSMENT = 'WAITLIST_VIRTUAL_ASSESSMENT',
  HORMONE_MEMBERSHIP = 'HORMONE_MEMBERSHIP',
  VIRTUAL_ASSESSMENT = 'VIRTUAL_ASSESSMENT',
  BASIC_HORMONE_ANALYSIS = 'BASIC_HORMONE_ANALYSIS',
  COMPREHENSIVE_GUT_ANALYSIS = 'COMPREHENSIVE_GUT_ANALYSIS',
  COMPREHENSIVE_GUT_MEMBERSHIP = 'COMPREHENSIVE_GUT_MEMBERSHIP',
  MENOPAUSE_LIFECYCLE_HORMONE_ANALYSIS = 'MENOPAUSE_LIFECYCLE_HORMONE_ANALYSIS',
  MENOPAUSE_LIFECYCLE_HORMONE_MEMBERSHIP = 'MENOPAUSE_LIFECYCLE_HORMONE_MEMBERSHIP',
  COMPREHENSIVE_HORMONE_ANALYSIS = 'COMPREHENSIVE_HORMONE_ANALYSIS',
  COMPREHENSIVE_HORMONE_MEMBERSHIP = 'COMPREHENSIVE_HORMONE_MEMBERSHIP',
  HEALTH_RISK_ASSESSMENT = 'HEALTH_RISK_ASSESSMENT',
  SHORT_PROGRAM = 'SHORT_PROGRAM',
  WAITLIST_SHORT_PROGRAM = 'WAITLIST_SHORT_PROGRAM',
  NO_PRODUCT = 'NO_PRODUCT',
  FREE_TRIAL = 'FREE_TRIAL',
  LABS_PLUS_COACHING = 'LABS_PLUS_COACHING',
  MEDICAL_ONLY = 'MEDICAL_ONLY',
  MEDICAL_PLUS_COACHING = 'MEDICAL_PLUS_COACHING',
  NEW_PROGRAMS_LITE = 'NEW_PROGRAMS_LITE',
  TOTAL_HEALTH = 'TOTAL_HEALTH',
  FOUNDATION = 'FOUNDATION'
}

export type PapiProduct = {
  __typename?: 'PapiProduct';
  type: PapiProductType;
  displayName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  shopSummary?: Maybe<Scalars['String']['output']>;
  renewalPeriod?: Maybe<PapiBillingPeriod>;
  deprecatedAt?: Maybe<Scalars['String']['output']>;
  plans: Array<PapiPlan>;
  /** Returns a list of plans that filters out complimentary plans and checks theyre available on the members center */
  purchasablePlans: Array<PapiPlan>;
  membershipType: PapiMembershipType;
  abbreviation: Scalars['String']['output'];
  agreements: Array<PapiAgreement>;
  sanityProduct?: Maybe<SanityProduct>;
};

export enum PapiBillingPeriod {
  DAY = 'DAY',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  TRIMESTER = 'TRIMESTER',
  HALF_YEAR = 'HALF_YEAR',
  YEAR = 'YEAR'
}

export type PapiPlan = {
  __typename?: 'PapiPlan';
  id: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  billingPeriod: PapiBillingPeriod;
  numberOfBillingCycles?: Maybe<Scalars['Int']['output']>;
  paymentRateInCents: Scalars['Int']['output'];
  minPersonAgeYears: Scalars['Int']['output'];
  maxPersonAgeYears?: Maybe<Scalars['Int']['output']>;
  deprecatedAt?: Maybe<Scalars['String']['output']>;
  defaultFollowOnPlan?: Maybe<PapiPlan>;
  defaultFollowOnPlanID?: Maybe<Scalars['ID']['output']>;
  sequenceID?: Maybe<Scalars['ID']['output']>;
  monthsDuration?: Maybe<Scalars['Int']['output']>;
  product: PapiProduct;
  totalPaymentInCents?: Maybe<Scalars['Int']['output']>;
  annualCostInCents: Scalars['Int']['output'];
  commitmentLength?: Maybe<Scalars['Int']['output']>;
  /** list of centers this plan is allowed to be purchased as a first purchase by a person with a role (<) parsley_staff */
  joinCentersAllowed: Array<PapiCenter>;
  insuranceType: PapiInsuranceType;
  consumerType?: Maybe<PapiConsumerType>;
};

export enum PapiInsuranceType {
  CASH_PAY = 'CASH_PAY',
  IN_NETWORK = 'IN_NETWORK',
  OUT_OF_NETWORK = 'OUT_OF_NETWORK',
  PARTNER_BILLING = 'PARTNER_BILLING'
}

export enum PapiConsumerType {
  CONSUMER_TYPE_UNSPECIFIED = 'CONSUMER_TYPE_UNSPECIFIED',
  DIRECT_CONSUMER = 'DIRECT_CONSUMER',
  ENTERPRISE_PARTNER = 'ENTERPRISE_PARTNER'
}

export enum PapiMembershipType {
  WAITLIST = 'WAITLIST',
  ENTRY = 'ENTRY',
  RENEWAL = 'RENEWAL',
  TRIAL = 'TRIAL'
}

export type PapiAgreement = {
  __typename?: 'PapiAgreement';
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
  type: PapiAgreementType;
};

export enum PapiAgreementType {
  HIPAA_AUTHORIZATION = 'HIPAA_AUTHORIZATION',
  MEMBERSHIP_TERMS = 'MEMBERSHIP_TERMS',
  NOTICE_PRIVACY_PRACTICES = 'NOTICE_PRIVACY_PRACTICES',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  SELF_PAYMENT_OF_SERVICE = 'SELF_PAYMENT_OF_SERVICE',
  TELEHEALTH_INFORMED_CONSENT = 'TELEHEALTH_INFORMED_CONSENT',
  TERMS_OF_USE = 'TERMS_OF_USE',
  SUPPLEMENT = 'SUPPLEMENT',
  CLINICAL_MEMBERSHIP = 'CLINICAL_MEMBERSHIP',
  MEMBERSHIP_AGREEMENT = 'MEMBERSHIP_AGREEMENT',
  ASSIGNMENT_OF_BENEFITS = 'ASSIGNMENT_OF_BENEFITS',
  CREDIT_CARD_AUTH = 'CREDIT_CARD_AUTH'
}

export type SanityProduct = {
  __typename?: 'SanityProduct';
  _id: Scalars['ID']['output'];
  productName?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['JSON']['output']>;
  planBenefits?: Maybe<Array<Maybe<SanityJoinPagePlanBenefit>>>;
  /** Hexadecimal color value */
  color?: Maybe<Scalars['String']['output']>;
  /** Details renewal flow */
  shortDurationLabel?: Maybe<Scalars['String']['output']>;
  isUsingPromoPrice?: Maybe<Scalars['Boolean']['output']>;
  prices?: Maybe<Array<Maybe<SanityProductPrices>>>;
};

export type SanityJoinPagePlanBenefit = {
  __typename?: 'SanityJoinPagePlanBenefit';
  _id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
};

export type SanityProductPrices = {
  __typename?: 'SanityProductPrices';
  amount?: Maybe<Scalars['Int']['output']>;
  billingPeriod?: Maybe<Scalars['String']['output']>;
  promoPrice?: Maybe<Scalars['Int']['output']>;
  promoMessage?: Maybe<Scalars['JSON']['output']>;
};

export type PapiPod = {
  __typename?: 'PapiPod';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum PapiProviderAttrType {
  IS_MEDICARE_OPTED_OUT = 'IS_MEDICARE_OPTED_OUT',
  MAX_PATIENT_FACING_HOURS = 'MAX_PATIENT_FACING_HOURS',
  WORK_DAYS_PER_WEEK = 'WORK_DAYS_PER_WEEK'
}

export type PapiProviderAttributeFilter = {
  attrs: Array<PapiProviderAttrType>;
};

export type PapiProviderAttr = {
  __typename?: 'PapiProviderAttr';
  id: Scalars['ID']['output'];
  providerID: Scalars['ID']['output'];
  attr: PapiProviderAttrType;
  value: Scalars['String']['output'];
};

export type SanityPerson = {
  __typename?: 'SanityPerson';
  _id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  bioRaw?: Maybe<Scalars['JSON']['output']>;
  imageSrc?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  authorByLine?: Maybe<Scalars['String']['output']>;
  backgroundRaw?: Maybe<Scalars['JSON']['output']>;
  biography?: Maybe<Array<Maybe<PapiProviderProfileBiography>>>;
};

export type PapiProviderProfileBiography = {
  __typename?: 'PapiProviderProfileBiography';
  content?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type PapiAppointmentCategoryDetails = {
  __typename?: 'PapiAppointmentCategoryDetails';
  /** Unique AppointmentCategory enum */
  category: PapiAppointmentCategory;
  /** Display color */
  color: Scalars['String']['output'];
  /** Display name */
  name: Scalars['String']['output'];
};

export type PapiPerson = {
  __typename?: 'PapiPerson';
  id: Scalars['ID']['output'];
  analyticsID: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  biologicalSex: PapiSex;
  genderIdentity?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  center?: Maybe<PapiCenter>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  /** consider using or updating currentPurchase */
  currentPlan?: Maybe<PapiPlanPurchase>;
  /** returns rectified purchase which abstracts away extensions */
  currentPurchase?: Maybe<PapiRectifiedPurchase>;
  allPlans?: Maybe<PapiPlanPurchaseConnection>;
  allPurchases: Array<PapiSequencePeriod>;
  appointments: Array<PapiAppointment>;
  /** Gets the appoinments by an array of statuses */
  appointmentsByStatuses: Array<PapiAppointment>;
  scheduledAppointments: Array<PapiScheduledAppointment>;
  paymentCards: PapiPaymentCardConnection;
  allPaymentCards: Array<PapiPaymentCard>;
  cardPaymentMethods: Array<PapiCardPaymentMethod>;
  mdhqID?: Maybe<Scalars['ID']['output']>;
  mdhqHost?: Maybe<Scalars['String']['output']>;
  mdhqPatientURL?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use membershipStatus */
  memberStatus?: Maybe<PapiMemberStatus>;
  /** Member's insurance company name */
  insurancePayer?: Maybe<Scalars['String']['output']>;
  insuranceGroupID?: Maybe<Scalars['String']['output']>;
  insuranceMemberID?: Maybe<Scalars['String']['output']>;
  /**
   * Time of most recent update to insurance information.
   * If this is NULL it means the user has not completed
   * filling out their insurance information.
   */
  insuranceUpdatedAt?: Maybe<Scalars['String']['output']>;
  joinedPracticeDate?: Maybe<Scalars['String']['output']>;
  /**
   * Get's the next plan purchase for the member that starts
   * some time in the future.
   * If the member is on a current ongoing plan then
   * their current plan will be returned.
   */
  nextPlan?: Maybe<PapiPlanPurchase>;
  /**
   * Gets the previous (non-extension) purchase for a member. If there is no previous
   * Null will be returned
   */
  previousPlan?: Maybe<PapiPlanPurchase>;
  /**
   * List of possible plans the member is allowed
   * to renew into or upgrade into from their current plan.
   * If the member has no current
   * plan then this will be a list of the plans a member
   * can purchase. This logic is currently hardcoded in PAPI.
   */
  renewalPlanOptions?: Maybe<Array<PapiPlan>>;
  currentPaymentCard?: Maybe<PapiPaymentCard>;
  firebaseCustomRegistrationToken?: Maybe<Scalars['String']['output']>;
  role: PapiRole;
  enrolledBy?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  hubspotID?: Maybe<Scalars['ID']['output']>;
  stripeCustomerID?: Maybe<Scalars['ID']['output']>;
  stripeSubscription?: Maybe<PapiStripeSubscription>;
  /** Is the member currently on free trial? */
  isOnFreeTrial?: Maybe<Scalars['Boolean']['output']>;
  googleAnalyticsID: Scalars['ID']['output'];
  legacyHintID?: Maybe<Scalars['ID']['output']>;
  helpScoutID?: Maybe<Scalars['ID']['output']>;
  mostRecentPlan?: Maybe<PapiPlanPurchase>;
  /** @deprecated Use membershipActivityStatus and/or membershipPaymentStatus */
  membershipStatus?: Maybe<PapiMembershipStatus>;
  membershipActivityStatus?: Maybe<PapiMembershipActivityStatus>;
  membershipPaymentStatus?: Maybe<PapiMembershipPaymentStatus>;
  availableProviders: Array<PapiProvider>;
  mostRecentDoctor?: Maybe<PapiProvider>;
  mostRecentCoach?: Maybe<PapiProvider>;
  assignedDoctor?: Maybe<PapiProvider>;
  assignedCoach?: Maybe<PapiProvider>;
  /** The advocate providers that the member has been assigned to */
  assignedAdvocates?: Maybe<Array<Maybe<PapiProvider>>>;
  psiScores?: Maybe<Array<Maybe<PapiPsiScore>>>;
  churnScore?: Maybe<Scalars['Float']['output']>;
  onboardingCompletedAt?: Maybe<Scalars['String']['output']>;
  telehealthAgreementAcceptedAt?: Maybe<Scalars['String']['output']>;
  InsightUser?: Maybe<Scalars['Boolean']['output']>;
  hipaaAuthorizationAcceptedAt?: Maybe<Scalars['String']['output']>;
  membershipTermsAcceptedAt?: Maybe<Scalars['String']['output']>;
  noticePrivacyPracticesAcceptedAt?: Maybe<Scalars['String']['output']>;
  privacyPolicyAcceptedAt?: Maybe<Scalars['String']['output']>;
  selfPaymentOfServicesTermsAcceptedAt?: Maybe<Scalars['String']['output']>;
  telehealthInformedConsentAcceptedAt?: Maybe<Scalars['String']['output']>;
  termsOfUseAcceptedAt?: Maybe<Scalars['String']['output']>;
  supplementAcceptedAt?: Maybe<Scalars['String']['output']>;
  clinicalMembershipAgreementAcceptedAt?: Maybe<Scalars['String']['output']>;
  membershipAgreementAcceptedAt?: Maybe<Scalars['String']['output']>;
  assignmentOfBenefitsAcceptedAt?: Maybe<Scalars['String']['output']>;
  creditCardAuthAcceptedAt?: Maybe<Scalars['String']['output']>;
  nudges: Array<PapiNudge>;
  allNudges: Array<PapiNudge>;
  allNudgesNewOnboarding: Array<PapiNudge>;
  permissions: PapiPermissions;
  visitNotes: Array<VisitNote>;
  documents: Array<MdhqDocument>;
  labs: Array<MdhqDocument>;
  handouts: Array<MdhqDocument>;
  invoices: Array<MdhqDocument>;
  medications: Array<Medication>;
  supplements: Array<Supplement>;
};


export type PapiPersonAppointmentsByStatusesArgs = {
  statuses: Array<PapiAppointmentStatus>;
};


export type PapiPersonPaymentCardsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};


export type PapiPersonAvailableProvidersArgs = {
  onlyTakingNewPatients?: InputMaybe<Scalars['Boolean']['input']>;
  excludeProductFilter?: InputMaybe<Scalars['Boolean']['input']>;
  allowAllProviders?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PapiPersonAllNudgesArgs = {
  limit: Scalars['Int']['input'];
};


export type PapiPersonAllNudgesNewOnboardingArgs = {
  limit: Scalars['Int']['input'];
};


export type PapiPersonDocumentsArgs = {
  folders: Array<Scalars['String']['input']>;
};

export type PapiPlanPurchase = {
  __typename?: 'PapiPlanPurchase';
  id: Scalars['ID']['output'];
  plan: PapiPlan;
  person: PapiPerson;
  payor: PapiPerson;
  center: PapiCenter;
  startTime: Scalars['String']['output'];
  endTime?: Maybe<Scalars['String']['output']>;
  /** The coupon used when making this purchase */
  coupon?: Maybe<PapiCoupon>;
  /** Internal value denoting how DAPI is handling a given purchase record */
  purchaseState: PapiPlanPurchaseState;
  paymentDueDate?: Maybe<Scalars['String']['output']>;
  /** The Stripe payment status for the most recent invoice on the purchase */
  paymentStatus: PapiPaymentStatus;
  /** @deprecated Use nextRenewalDate */
  renewalDate?: Maybe<Scalars['String']['output']>;
  commitmentEndDate?: Maybe<Scalars['String']['output']>;
  /** The status on the stripe subscription associated with a given plan purchase record  */
  stripeSubscriptionStatus?: Maybe<PapiStripeSubscriptionStatus>;
  nextBillDate?: Maybe<Scalars['String']['output']>;
  legacyHintMembershipID?: Maybe<Scalars['ID']['output']>;
  endDateIncludingExtensions?: Maybe<Scalars['String']['output']>;
  /** Whether or not a new plan purchase starts at the end date */
  renewsAtEndDate?: Maybe<Scalars['Boolean']['output']>;
  extensionOf?: Maybe<PapiPlanPurchase>;
  renewalPeriods: Array<PapiPeriod>;
  nextRenewalDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use latestPause */
  pause?: Maybe<PapiPeriod>;
  latestPause?: Maybe<PapiPeriod>;
};

export type PapiCoupon = {
  __typename?: 'PapiCoupon';
  slug: Scalars['String']['output'];
  description: Scalars['String']['output'];
  discount: PapiDiscount;
  /** Maximum amount of times coupon can be redeemed */
  maxRedemptions?: Maybe<Scalars['Int']['output']>;
  /** This is the expiration date of the coupon ('redeem by this date') */
  redeemBy?: Maybe<Scalars['String']['output']>;
  centers: Array<PapiCenter>;
  products: Array<PapiProduct>;
  /** Number of months coupon can be applied */
  duration: PapiCouponDuration;
  plans: Array<PapiPlan>;
  /** the timestamp of when the coupon was marked as deprecated */
  depricatedAt?: Maybe<Scalars['String']['output']>;
};

export type PapiDiscount = PapiFlatDiscount | PapiPercentageDiscount;

export type PapiFlatDiscount = {
  __typename?: 'PapiFlatDiscount';
  amountOffCents: Scalars['Int']['output'];
};

export type PapiPercentageDiscount = {
  __typename?: 'PapiPercentageDiscount';
  percentOff: Scalars['Float']['output'];
};

export type PapiCouponDuration = PapiCouponApplies | PapiCouponRepeats;

export type PapiCouponApplies = {
  __typename?: 'PapiCouponApplies';
  apply: PapiCouponApplication;
};

export enum PapiCouponApplication {
  ONCE = 'ONCE',
  FOREVER = 'FOREVER'
}

export type PapiCouponRepeats = {
  __typename?: 'PapiCouponRepeats';
  months: Scalars['Int']['output'];
};

export enum PapiPlanPurchaseState {
  PENDING_SUBSCRIPTION_START = 'PENDING_SUBSCRIPTION_START',
  PENDING_PLAN_START = 'PENDING_PLAN_START',
  UNABLE_TO_PROCESS_SUBSCRIPTION_CARD_ERROR = 'UNABLE_TO_PROCESS_SUBSCRIPTION_CARD_ERROR',
  IN_FLIGHT_OPEN_ENDED = 'IN_FLIGHT_OPEN_ENDED',
  TERMINATE_AT_END = 'TERMINATE_AT_END',
  TERMINATING_AT_END = 'TERMINATING_AT_END',
  ENDED_CANCELLED = 'ENDED_CANCELLED',
  ENDED_NATURALLY = 'ENDED_NATURALLY',
  TRANSITION_FROM_INSURANCE = 'TRANSITION_FROM_INSURANCE',
  TRANSITION_TO_INSURANCE = 'TRANSITION_TO_INSURANCE'
}

export enum PapiPaymentStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID',
  PENDING = 'PENDING',
  IN_ARREARS = 'IN_ARREARS',
  UNKNOWN = 'UNKNOWN'
}

export enum PapiStripeSubscriptionStatus {
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  TRIALING = 'TRIALING',
  ACTIVE = 'ACTIVE',
  PAST_DUE = 'PAST_DUE',
  CANCELED = 'CANCELED',
  UNPAID = 'UNPAID'
}

export type PapiPeriod = {
  __typename?: 'PapiPeriod';
  startDate: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
};

export type PapiRectifiedPurchase = {
  __typename?: 'PapiRectifiedPurchase';
  id: Scalars['ID']['output'];
  purchasePeriodId?: Maybe<Scalars['ID']['output']>;
  displayName: Scalars['String']['output'];
  person: PapiPerson;
  productType: PapiProductType;
  renewalPeriods: Array<PapiPeriod>;
  center?: Maybe<PapiCenter>;
  startTime?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  sequenceId?: Maybe<Scalars['String']['output']>;
  membershipType?: Maybe<PapiMembershipType>;
  nextRenewalDate?: Maybe<Scalars['String']['output']>;
  latestPause?: Maybe<PapiPeriod>;
  purchasePeriodEndTime?: Maybe<Scalars['String']['output']>;
};

export type PapiPlanPurchaseConnection = {
  __typename?: 'PapiPlanPurchaseConnection';
  edges: Array<PapiPlanPurchaseEdge>;
  pageInfo: PapiPageInfo;
};

export type PapiPlanPurchaseEdge = {
  __typename?: 'PapiPlanPurchaseEdge';
  node: PapiPlanPurchase;
  cursor: Scalars['ID']['output'];
};

export type PapiPageInfo = {
  __typename?: 'PapiPageInfo';
  hasPreviousPage: Scalars['Boolean']['output'];
  hasNextPage: Scalars['Boolean']['output'];
};

export type PapiSequencePeriod = {
  __typename?: 'PapiSequencePeriod';
  purchasePeriodId: Scalars['ID']['output'];
  purchaseId: Scalars['ID']['output'];
  purchaseName: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
};

export type PapiAppointment = {
  __typename?: 'PapiAppointment';
  id: Scalars['ID']['output'];
  paymentCostInCents: Scalars['Int']['output'];
  /** Possibly not unique Acuity Appointment ID */
  acuityID?: Maybe<Scalars['String']['output']>;
  /**
   * Get the list of Providers that can schedule for this appoinment.
   * @deprecated Use `availableProvidersForNewMember` instead
   */
  availableProviders: Array<PapiAppointmentProvider>;
  /** Get the list of Providers that a new member can with schedule for this appointment. */
  availableProvidersForNewMember: Array<PapiAppointmentProvider>;
  /**
   * Get the provider and next available slot for the given provider. Returns null
   * if the provider does not serve this visit type. Can optionally take a formats
   * array as input to be used as filter option.
   */
  nextAvailabilityForProvider?: Maybe<PapiAppointmentProvider>;
  /** @deprecated Use details */
  category?: Maybe<PapiAppointmentCategory>;
  /**
   * Type of appointment, e.g. Medical Initial, Coaching, Lab, and related details
   * @deprecated Use details
   */
  categoryDetails?: Maybe<PapiAppointmentCategoryDetails>;
  /** DAPI Center where the provider is servicing */
  center?: Maybe<PapiCenter>;
  centerID: Scalars['ID']['output'];
  /** display order of the appointment for sequence */
  completedAt?: Maybe<Scalars['String']['output']>;
  displayOrder: Scalars['Int']['output'];
  /** duration in minutes of the appointment */
  duration: Scalars['Int']['output'];
  /** UTC epoch time of the end of the appointment */
  endTime?: Maybe<Scalars['String']['output']>;
  /** Online or in-person format of appointment */
  format: PapiAppointmentFormat;
  /** Is this appointment free? If true, does not count toward allotted appointments for PlanPurchase */
  isComplimentary: Scalars['Boolean']['output'];
  /** Optional notes associated with the appointment */
  metadata?: Maybe<Scalars['String']['output']>;
  /** DAPI Person for the member having this appointment */
  person?: Maybe<PapiPerson>;
  personID: Scalars['ID']['output'];
  /** DAPI Provider servicing this appointment */
  provider?: Maybe<PapiProvider>;
  providerID?: Maybe<Scalars['ID']['output']>;
  purchasePeriodID?: Maybe<Scalars['ID']['output']>;
  planPurchase?: Maybe<PapiPlanPurchase>;
  /** List of questionnaires related to this appointment */
  questionnaires: Array<PapiQuestionnaire>;
  /** Lowest status of questionnaires related to this appointment, e.g. started, accepted */
  questionnairesStatus?: Maybe<PapiQuestionnaireStatus>;
  /** UTC epoch time of the start of the appointment */
  startTime?: Maybe<Scalars['String']['output']>;
  /** Scheduling status, e.g. scheduled, rescheduled, canceled */
  status: PapiAppointmentStatus;
  /** Optional location of visit, e.g. Zoom link */
  location?: Maybe<Scalars['String']['output']>;
  /** object with metadata information about an appointment */
  details: PapiAppointmentDetails;
  /** The relative URL for scheduling this appointment in My Parsley */
  portalScheduleURL: Scalars['String']['output'];
  /** Person who scheduled the appointment. If scheduled from acuity this will be null. */
  scheduledBy?: Maybe<PapiPerson>;
  /** Last updated time */
  lastUpdated?: Maybe<Scalars['String']['output']>;
  /** Visit Note id. If there's no visit note this will be null. */
  visitNoteID?: Maybe<Scalars['ID']['output']>;
  /**
   * This is the bounds that this appointment can be scheduled. It is bound by the
   * plan end time, with extensions or free trial with extensions
   */
  schedulablePeriod?: Maybe<PapiSchedulablePeriod>;
  availableWindows: Array<PapiTimeWindow>;
  encounterID?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
};


export type PapiAppointmentAvailableProvidersArgs = {
  isNewPatient?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PapiAppointmentAvailableProvidersForNewMemberArgs = {
  formats?: InputMaybe<Array<PapiAppointmentFormat>>;
  shouldFilterByPod?: InputMaybe<Scalars['Boolean']['input']>;
  filterByPodID?: InputMaybe<Scalars['ID']['input']>;
  excludeProviderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  allowAllProviders?: InputMaybe<Scalars['Boolean']['input']>;
  onlyTakingNewPatients?: InputMaybe<Scalars['Boolean']['input']>;
  excludeProductFilter?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PapiAppointmentNextAvailabilityForProviderArgs = {
  providerId: Scalars['ID']['input'];
  formats?: InputMaybe<Array<PapiAppointmentFormat>>;
};

export type PapiQuestionnaire = {
  __typename?: 'PapiQuestionnaire';
  id: Scalars['ID']['output'];
  personID: Scalars['ID']['output'];
  person?: Maybe<PapiPerson>;
  type: PapiQuestionnaireType;
  status: PapiQuestionnaireStatus;
  title: Scalars['String']['output'];
  /** UI schema used to create this form */
  uiSchema: Scalars['JSON']['output'];
  /** Data schema used to create this form */
  schema: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Member's answers */
  answers: Scalars['JSON']['output'];
  appointmentID?: Maybe<Scalars['ID']['output']>;
  appointment?: Maybe<PapiAppointment>;
  startedAt?: Maybe<Scalars['String']['output']>;
  updatedByPersonAt?: Maybe<Scalars['String']['output']>;
  submittedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  /** An estimated percentage of how far the user is done */
  progress?: Maybe<Scalars['Int']['output']>;
};

export enum PapiQuestionnaireType {
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  FOOD_DIARY = 'FOOD_DIARY',
  HEALTH_COACH_PREP = 'HEALTH_COACH_PREP',
  MEDICAL_HISTORY = 'MEDICAL_HISTORY',
  MEDICARE_AGREEMENT = 'MEDICARE_AGREEMENT',
  MERIWETHER_GUT = 'MERIWETHER_GUT',
  MERIWETHER_HORMONE = 'MERIWETHER_HORMONE',
  PEDIATRICS_CONSENT = 'PEDIATRICS_CONSENT',
  PRIVACY_AGREEMENT = 'PRIVACY_AGREEMENT',
  READINESS_ASSESSMENT = 'READINESS_ASSESSMENT',
  RESEARCH_CONSENT = 'RESEARCH_CONSENT',
  SOCIAL_HISTORY = 'SOCIAL_HISTORY',
  SUPPLEMENT_AGREEMENT = 'SUPPLEMENT_AGREEMENT',
  SYMPTOM_INDEX = 'SYMPTOM_INDEX',
  TELEHEALTH_CONSENT = 'TELEHEALTH_CONSENT',
  HEALTH_CONCERN = 'HEALTH_CONCERN',
  VISIT_PREP = 'VISIT_PREP',
  LAB_PREP = 'LAB_PREP',
  DLQI = 'DLQI'
}

export enum PapiQuestionnaireStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  STARTED = 'STARTED',
  SUBMITTED = 'SUBMITTED',
  UNSTARTED = 'UNSTARTED'
}

export type PapiAppointmentDetails = {
  __typename?: 'PapiAppointmentDetails';
  id: Scalars['ID']['output'];
  shortName: Scalars['String']['output'];
  productType: PapiProductType;
  name: Scalars['String']['output'];
  displayColor: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  group: PapiAppointmentGroup;
  duration?: Maybe<Scalars['Int']['output']>;
};

export enum PapiAppointmentGroup {
  GROUP_UNSPECIFIED = 'GROUP_UNSPECIFIED',
  MEDICAL = 'MEDICAL',
  COACHING = 'COACHING',
  LAB = 'LAB',
  CAREMANAGER = 'CAREMANAGER',
  CONSULTATION = 'CONSULTATION'
}

export type PapiSchedulablePeriod = {
  __typename?: 'PapiSchedulablePeriod';
  startTime?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
};

export type PapiTimeWindow = {
  __typename?: 'PapiTimeWindow';
  start?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
};

export type PapiPaymentCardConnection = {
  __typename?: 'PapiPaymentCardConnection';
  edges: Array<PapiPaymentCardEdge>;
  pageInfo: PapiPageInfo;
};

export type PapiPaymentCardEdge = {
  __typename?: 'PapiPaymentCardEdge';
  node: PapiPaymentCard;
  cursor: Scalars['ID']['output'];
};

export type PapiPaymentCard = {
  __typename?: 'PapiPaymentCard';
  id: Scalars['ID']['output'];
  personID: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  last4Digits: Scalars['String']['output'];
  expiryMonth: Scalars['Int']['output'];
  expiryYear: Scalars['Int']['output'];
  deleted: Scalars['Boolean']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  lastRejectedReason?: Maybe<Scalars['String']['output']>;
};

export type PapiCardPaymentMethod = {
  __typename?: 'PapiCardPaymentMethod';
  id: Scalars['String']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  last4Digits?: Maybe<Scalars['String']['output']>;
  expiryMonth?: Maybe<Scalars['Int']['output']>;
  expiryYear?: Maybe<Scalars['Int']['output']>;
  fundingType?: Maybe<Scalars['String']['output']>;
};

export enum PapiMemberStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  ENDED = 'ENDED',
  NONMEMBER = 'NONMEMBER'
}

export enum PapiRole {
  PARSLEY_ANONYMOUS = 'PARSLEY_ANONYMOUS',
  PARSLEY_USER = 'PARSLEY_USER',
  PARSLEY_MEMBER = 'PARSLEY_MEMBER',
  PARSLEY_STAFF = 'PARSLEY_STAFF',
  PARSLEY_ADMIN = 'PARSLEY_ADMIN',
  PARSLEY_SUPERADMIN = 'PARSLEY_SUPERADMIN'
}

export type PapiStripeSubscription = {
  __typename?: 'PapiStripeSubscription';
  onFreeTrial: Scalars['Boolean']['output'];
  trialEndDate?: Maybe<Scalars['String']['output']>;
};

export enum PapiMembershipStatus {
  ACTIVE_PAID = 'ACTIVE_PAID',
  ACTIVE_UNPAID = 'ACTIVE_UNPAID',
  ACTIVE_ON_EXTENSION = 'ACTIVE_ON_EXTENSION',
  ACTIVE_COMPLIMENTARY = 'ACTIVE_COMPLIMENTARY',
  INACTIVE_WAITLISTED = 'INACTIVE_WAITLISTED',
  INACTIVE_PENDING = 'INACTIVE_PENDING',
  INACTIVE_UNPAID = 'INACTIVE_UNPAID',
  INACTIVE_CHURNED = 'INACTIVE_CHURNED',
  INACTIVE_PAUSED = 'INACTIVE_PAUSED',
  INACTIVE_PROSPECTIVE = 'INACTIVE_PROSPECTIVE'
}

export enum PapiMembershipActivityStatus {
  MEMBERSHIP_ACTIVE = 'MEMBERSHIP_ACTIVE',
  MEMBERSHIP_ON_EXTENSION = 'MEMBERSHIP_ON_EXTENSION',
  MEMBERSHIP_WAITLISTED = 'MEMBERSHIP_WAITLISTED',
  MEMBERSHIP_PENDING = 'MEMBERSHIP_PENDING',
  MEMBERSHIP_PAUSED = 'MEMBERSHIP_PAUSED',
  MEMBERSHIP_PROSPECTIVE = 'MEMBERSHIP_PROSPECTIVE',
  MEMBERSHIP_COMPLIMENTARY = 'MEMBERSHIP_COMPLIMENTARY',
  MEMBERSHIP_INACTIVE = 'MEMBERSHIP_INACTIVE'
}

export enum PapiMembershipPaymentStatus {
  MEMBERSHIP_PAID = 'MEMBERSHIP_PAID',
  MEMBERSHIP_UNPAID = 'MEMBERSHIP_UNPAID'
}

export type PapiPsiScore = {
  __typename?: 'PapiPsiScore';
  score: Scalars['Int']['output'];
  categories: Array<PapiPsiCategory>;
  total: Scalars['Int']['output'];
  date?: Maybe<Scalars['String']['output']>;
};

export type PapiPsiCategory = {
  __typename?: 'PapiPsiCategory';
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  symptoms: Array<PapiPsiSymptom>;
};

export type PapiPsiSymptom = {
  __typename?: 'PapiPsiSymptom';
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  score: Scalars['Int']['output'];
};

export type PapiNudge = {
  __typename?: 'PapiNudge';
  /** id of nudge */
  id: Scalars['ID']['output'];
  /** name of nudge */
  name: Scalars['String']['output'];
  /** tasks of nudge */
  tasks: Array<PapiNudgeTask>;
  /** id of owner of nudge */
  personID: Scalars['ID']['output'];
  /** display order of Nudge */
  displayOrder: Scalars['Int']['output'];
  /** status of Nudge */
  status: PapiNudgeStatus;
  /** description of Nudge */
  description?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
};

export type PapiNudgeTask = {
  __typename?: 'PapiNudgeTask';
  /** data of that nudge */
  resource: PapiNudgeResource;
  /** progress of task */
  progress?: Maybe<Scalars['Int']['output']>;
  /** type of data that is return. e.g, QUESTIONNAIRE */
  resourceType: PapiNudgeType;
  /** status of Nudge */
  status: PapiNudgeStatus;
  locked?: Maybe<Scalars['Boolean']['output']>;
};

export type PapiNudgeResource = PapiQuestionnaire | PapiAppointment | PapiNudgeMessage;

export type PapiNudgeMessage = {
  __typename?: 'PapiNudgeMessage';
  /** title of message */
  title: Scalars['String']['output'];
  /** actual message */
  message: Scalars['String']['output'];
  /** type of message */
  type: PapiNudgeMessageType;
};

export enum PapiNudgeMessageType {
  /** Type of message to nudge */
  RENEWAL = 'RENEWAL',
  UPGRADE = 'UPGRADE'
}

export enum PapiNudgeType {
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  APPOINTMENT = 'APPOINTMENT',
  MESSAGE = 'MESSAGE'
}

export enum PapiNudgeStatus {
  UNSTARTED = 'UNSTARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED'
}

export type PapiPermissions = {
  __typename?: 'PapiPermissions';
  adminMessaging: Scalars['Boolean']['output'];
  doctorMessaging: Scalars['Boolean']['output'];
  healthCoachMessaging: Scalars['Boolean']['output'];
  scheduling: Scalars['Boolean']['output'];
  perks: Scalars['Boolean']['output'];
  healthPlan: Scalars['Boolean']['output'];
  notes: Scalars['Boolean']['output'];
  prescription: Scalars['Boolean']['output'];
  insurance: Scalars['Boolean']['output'];
  uploadLabs: Scalars['Boolean']['output'];
  cancel: Scalars['Boolean']['output'];
};

export type VisitNote = {
  __typename?: 'VisitNote';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  encounterType: EncounterType;
  encounterTypeName?: Maybe<Scalars['String']['output']>;
  dateOfService: Scalars['String']['output'];
  createdBy?: Maybe<MdhqUser>;
  createdAt?: Maybe<Scalars['String']['output']>;
  soapSections?: Maybe<Array<SoapObject>>;
  soapPlans?: Maybe<Array<SoapObject>>;
  isLegacy?: Maybe<Scalars['Boolean']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  signedBy?: Maybe<MdhqUser>;
  assessments: Array<Maybe<MdhqDocument>>;
  orders: Array<Maybe<MdhqDocument>>;
};

export type EncounterType = {
  __typename?: 'EncounterType';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MdhqUser = {
  __typename?: 'MdhqUser';
  id: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type SoapObject = {
  __typename?: 'SoapObject';
  id: Scalars['ID']['output'];
  heading?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
};

export type MdhqDocument = {
  __typename?: 'MdhqDocument';
  reviewedBy?: Maybe<MdhqUser>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notifications?: Maybe<Array<Maybe<Notification>>>;
  reviewedAt?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  folder?: Maybe<Scalars['String']['output']>;
  subfolder?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  content: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  object: Scalars['String']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID']['output'];
  body: Scalars['String']['output'];
};

export type Medication = {
  __typename?: 'Medication';
  dateExpired?: Maybe<Scalars['String']['output']>;
  genericName?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructionsToPt?: Maybe<Scalars['String']['output']>;
  isPending?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  strength?: Maybe<Scalars['String']['output']>;
  isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  isAdminRecord?: Maybe<Scalars['Boolean']['output']>;
  drugDetailsID?: Maybe<Scalars['String']['output']>;
};

export type Supplement = {
  __typename?: 'Supplement';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPending?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  isAdminRecord?: Maybe<Scalars['Boolean']['output']>;
};

export type PapiDailyAllAvailabilitySet = {
  __typename?: 'PapiDailyAllAvailabilitySet';
  appointmentID: Scalars['ID']['output'];
  startTime: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  format: PapiAppointmentFormat;
  dailyAvailability: Array<PapiDayAllAvailability>;
};

export type PapiDayAllAvailability = {
  __typename?: 'PapiDayAllAvailability';
  /** Date: Is the day the the availability belongs to. */
  date: Scalars['String']['output'];
  /** Array with available slots and providers that match slot */
  availability: Array<PapiApppointmentSlotWithProviders>;
};

export type PapiApppointmentSlotWithProviders = {
  __typename?: 'PapiApppointmentSlotWithProviders';
  slots: Array<Scalars['ID']['output']>;
  start: Scalars['String']['output'];
  end: Scalars['String']['output'];
  providers: Array<Scalars['ID']['output']>;
  providerAppointmentTypeIds: Array<Scalars['ID']['output']>;
};

export type PapiNextAvailability = {
  __typename?: 'PapiNextAvailability';
  providerID: Scalars['ID']['output'];
  availableSlot: PapiAppointmentSlot;
};

export type PapiAppointmentSequence = {
  __typename?: 'PapiAppointmentSequence';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  typeID: Scalars['ID']['output'];
};

export type PapiCouponConnection = {
  __typename?: 'PapiCouponConnection';
  edges: Array<PapiCouponEdge>;
  pageInfo: PapiPageInfo;
};

export type PapiCouponEdge = {
  __typename?: 'PapiCouponEdge';
  node: PapiCoupon;
  cursor: Scalars['ID']['output'];
};

export type PapiCouponValidationResponse = {
  __typename?: 'PapiCouponValidationResponse';
  slug: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discount: PapiDiscount;
};

export type PapiInsurancePayer = {
  __typename?: 'PapiInsurancePayer';
  id: Scalars['ID']['output'];
  nationalPayerId: Scalars['ID']['output'];
  payerName: Scalars['String']['output'];
  accepted: Scalars['Boolean']['output'];
};

export type PapiSubscriberRelationship = {
  __typename?: 'PapiSubscriberRelationship';
  id: Scalars['ID']['output'];
  description: Scalars['String']['output'];
};

export type PapiInsuranceSubscriber = {
  __typename?: 'PapiInsuranceSubscriber';
  id: Scalars['ID']['output'];
  personId: Scalars['ID']['output'];
  payerId?: Maybe<Scalars['ID']['output']>;
  groupId?: Maybe<Scalars['ID']['output']>;
  planId?: Maybe<Scalars['ID']['output']>;
  relationshipToPatient?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  planStartDate?: Maybe<Scalars['String']['output']>;
  planEndDate?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
  sex: PapiSex;
  country: Scalars['String']['output'];
  inactiveAt?: Maybe<Scalars['String']['output']>;
  payerDetails?: Maybe<PapiInsurancePayer>;
  createdAt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  copayAmountInCents?: Maybe<Scalars['Int']['output']>;
};

export type PapiRunEligibilityCheckResponse = {
  __typename?: 'PapiRunEligibilityCheckResponse';
  successful: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  followupAction?: Maybe<Scalars['String']['output']>;
  possibleResolution?: Maybe<Scalars['String']['output']>;
  requiresManualVerification: Scalars['Boolean']['output'];
  checkedAt?: Maybe<Scalars['String']['output']>;
};

export type PapiRunEligibilityCheckInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  memberID: Scalars['String']['input'];
  nationalPayorID: Scalars['String']['input'];
  centerID?: InputMaybe<Scalars['ID']['input']>;
};

export type PapiRealtimeEligibilityCheckInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  memberID: Scalars['String']['input'];
  nationalPayorID: Scalars['String']['input'];
  centerID: Scalars['ID']['input'];
};

export type PapiRealtimeEligibilityCheckResponse = {
  __typename?: 'PapiRealtimeEligibilityCheckResponse';
  planStatus: Scalars['String']['output'];
  networkStatus: Scalars['String']['output'];
  checkedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  followupAction?: Maybe<Scalars['String']['output']>;
  possibleResolution?: Maybe<Scalars['String']['output']>;
  planNames: Array<Scalars['String']['output']>;
};

export type PapiLeadEligibilityCheck = {
  __typename?: 'PapiLeadEligibilityCheck';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  dateOfBirth: Scalars['String']['output'];
  memberID: Scalars['String']['output'];
  nationalPayorID: Scalars['String']['output'];
  payorName?: Maybe<Scalars['String']['output']>;
  planStatus: Scalars['String']['output'];
  networkStatus: Scalars['String']['output'];
  checkedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  planNames?: Maybe<Scalars['String']['output']>;
  centerState?: Maybe<Scalars['String']['output']>;
};

export type PapiInsurancePayerCenters = {
  __typename?: 'PapiInsurancePayerCenters';
  insurancePayerID: Scalars['ID']['output'];
  centerID: Scalars['ID']['output'];
};

export type PapiPurchaseReceipt = {
  __typename?: 'PapiPurchaseReceipt';
  purpose: Scalars['String']['output'];
  description: Scalars['String']['output'];
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  status: Scalars['String']['output'];
  hostedInvoiceURL?: Maybe<Scalars['String']['output']>;
  invoicePDF?: Maybe<Scalars['String']['output']>;
  charges: Array<PapiReceiptCharge>;
  createdAt: Scalars['String']['output'];
};

export type PapiReceiptCharge = {
  __typename?: 'PapiReceiptCharge';
  id: Scalars['String']['output'];
  description: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  refunded: Scalars['Boolean']['output'];
  receiptURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
};

export type PapiInvoice = {
  __typename?: 'PapiInvoice';
  id: Scalars['ID']['output'];
  dueDate?: Maybe<Scalars['String']['output']>;
  status: PapiPaymentStatus;
  invoicePDF?: Maybe<Scalars['String']['output']>;
  hostedInvoiceURL?: Maybe<Scalars['String']['output']>;
  amountDue: Scalars['Int']['output'];
  periodStart: Scalars['String']['output'];
  total: Scalars['Int']['output'];
  number?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  isLegacy: Scalars['Boolean']['output'];
  latestCharge?: Maybe<PapiCharge>;
};

export type PapiCharge = {
  __typename?: 'PapiCharge';
  id: Scalars['ID']['output'];
  payor?: Maybe<PapiPerson>;
  paymentCard?: Maybe<PapiPaymentCard>;
  statementDescriptor?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  amount: Scalars['Int']['output'];
  paid: Scalars['Boolean']['output'];
  receiptNumber?: Maybe<Scalars['String']['output']>;
  refunded: Scalars['Boolean']['output'];
  amountRefunded?: Maybe<Scalars['Int']['output']>;
  failureCode?: Maybe<Scalars['String']['output']>;
  failureMessage?: Maybe<Scalars['String']['output']>;
  liveInProd: Scalars['Boolean']['output'];
  created?: Maybe<Scalars['String']['output']>;
};

export type PapiLegalAgreement = {
  __typename?: 'PapiLegalAgreement';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
  latest: Scalars['Boolean']['output'];
  publishedAt: Scalars['String']['output'];
  type: PapiLegalAgreementType;
};

export enum PapiLegalAgreementType {
  TERMS_OF_USE = 'TERMS_OF_USE',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  ASSIGNMENT_OF_BENEFITS = 'ASSIGNMENT_OF_BENEFITS',
  MEMBERSHIP_AGREEMENT = 'MEMBERSHIP_AGREEMENT',
  CLINICAL_AGREEMENT = 'CLINICAL_AGREEMENT',
  INFORMED_CONSENT_FOR_TELEHEALTH_SERVICES = 'INFORMED_CONSENT_FOR_TELEHEALTH_SERVICES',
  HIPAA_NOTICE_OF_PRIVACY_PRACTICES = 'HIPAA_NOTICE_OF_PRIVACY_PRACTICES',
  PRIVATE_COMMUNICATION_CONSENT = 'PRIVATE_COMMUNICATION_CONSENT',
  PAYMENT_METHOD_AUTHORIZATION_CONSENT = 'PAYMENT_METHOD_AUTHORIZATION_CONSENT',
  MEDICARE_AGREEMENT = 'MEDICARE_AGREEMENT',
  SAME_STATE_AS_VISIT_AGREEMENT = 'SAME_STATE_AS_VISIT_AGREEMENT'
}

export type PapiPersonEmergencyContact = {
  __typename?: 'PapiPersonEmergencyContact';
  personId: Scalars['ID']['output'];
  relationship: PapiRelationship;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
};

export enum PapiRelationship {
  UNSPECIFIED_RELATIONSHIP = 'UNSPECIFIED_RELATIONSHIP',
  PARENT = 'PARENT',
  PARTNER = 'PARTNER',
  SIBLING = 'SIBLING',
  OTHER_RELATIONSHIP = 'OTHER_RELATIONSHIP',
  FRIEND = 'FRIEND',
  RELATIVE = 'RELATIVE',
  COWORKER = 'COWORKER'
}

export type PapiRecaptchaInput = {
  token: Scalars['String']['input'];
  ipAddress: Scalars['String']['input'];
};

export type PapiTimeline = {
  __typename?: 'PapiTimeline';
  description?: Maybe<Scalars['String']['output']>;
  group: PapiTimelineGroup;
  name: Scalars['String']['output'];
  status: PapiTimelineStatus;
};

export type PapiTimelineGroup = PapiTimelineAppointmentGroup | PapiTimelineHealthyHomeworkGroup;

export type PapiTimelineAppointmentGroup = {
  __typename?: 'PapiTimelineAppointmentGroup';
  appointmentGroup?: Maybe<PapiAppointmentGroup>;
};

export type PapiTimelineHealthyHomeworkGroup = {
  __typename?: 'PapiTimelineHealthyHomeworkGroup';
  healthyHomeworkGroup?: Maybe<PapiHealthyHomeworkGroup>;
};

export enum PapiHealthyHomeworkGroup {
  HEALTHY_HOMEWORK = 'HEALTHY_HOMEWORK'
}

export type PapiTimelineStatus = PapiTimelineAppointmentStatus | PapiTimelineHealthyHomeworkStatus;

export type PapiTimelineAppointmentStatus = {
  __typename?: 'PapiTimelineAppointmentStatus';
  appointmentStatus?: Maybe<PapiAppointmentStatus>;
};

export type PapiTimelineHealthyHomeworkStatus = {
  __typename?: 'PapiTimelineHealthyHomeworkStatus';
  healthyHomeworkStatus?: Maybe<PapiHealthyHomeworkStatus>;
};

export enum PapiHealthyHomeworkStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  UNSTARTED = 'UNSTARTED'
}

export enum PapiPreEligibilityStatus {
  ELIGIBILITY_STATUS_UNKNOWN = 'ELIGIBILITY_STATUS_UNKNOWN',
  INELIGIBLE = 'INELIGIBLE',
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE_PAYER = 'INELIGIBLE_PAYER'
}

export type PapiPlanConnection = {
  __typename?: 'PapiPlanConnection';
  edges: Array<PapiPlanEdge>;
  pageInfo: PapiPageInfo;
};

export type PapiPlanEdge = {
  __typename?: 'PapiPlanEdge';
  node: PapiPlan;
  cursor: Scalars['ID']['output'];
};

export type PapiNewMemberPlan = {
  __typename?: 'PapiNewMemberPlan';
  productType: PapiProductType;
  quotes: Array<PapiNewMemberQuote>;
};

export type PapiNewMemberQuote = {
  __typename?: 'PapiNewMemberQuote';
  plan: PapiPlan;
  coupon?: Maybe<PapiCoupon>;
  numberOfDiscountedBillingCycles: Scalars['Int']['output'];
  firstBill: PapiUpcomingBill;
  nextBill: PapiUpcomingBill;
};

export type PapiUpcomingBill = {
  __typename?: 'PapiUpcomingBill';
  amountInCents: Scalars['Int']['output'];
  amountInCentsAfterCoupon: Scalars['Int']['output'];
  date: Scalars['String']['output'];
};

export type PapiQuote = {
  __typename?: 'PapiQuote';
  total: PapiQuoteItem;
  original?: Maybe<PapiQuoteItem>;
  coupon?: Maybe<PapiCouponQuoteItem>;
};

export type PapiQuoteItem = {
  __typename?: 'PapiQuoteItem';
  text: Scalars['String']['output'];
  amountInCents: Scalars['Int']['output'];
};

export type PapiCouponQuoteItem = {
  __typename?: 'PapiCouponQuoteItem';
  text: Scalars['String']['output'];
  amountInCents: Scalars['Int']['output'];
  id: Scalars['String']['output'];
};

export type PapiAvailabititiesResponse = {
  __typename?: 'PapiAvailabititiesResponse';
  center: PapiCenter;
  isWithinRange: Scalars['Boolean']['output'];
  plans: Array<PapiAvailabititiesPlansResponse>;
};

export type PapiAvailabititiesPlansResponse = {
  __typename?: 'PapiAvailabititiesPlansResponse';
  planId: Scalars['ID']['output'];
  waitlistedPlanId?: Maybe<Scalars['ID']['output']>;
};

export type PapiAllAvailabititiesResponse = {
  __typename?: 'PapiAllAvailabititiesResponse';
  center: PapiCenter;
  plans: Array<PapiAvailabititiesPlansResponse>;
};

export type PapiAllPlansWithCentersResponse = {
  __typename?: 'PapiAllPlansWithCentersResponse';
  plan: PapiPlan;
  joinableCenters: Array<PapiCenter>;
  waitlistedCenters: Array<PapiCenter>;
};

export type PapiUsState = {
  __typename?: 'PapiUSState';
  abbreviation: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum PapiPaidLatestInvoiceStatus {
  PAID = 'PAID',
  IN_ARREARS = 'IN_ARREARS',
  EXPIRED = 'EXPIRED'
}

export type PapiOneTimePurchaseInput = {
  personId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  centerId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: Scalars['String']['input'];
};

/** Member-facing provider details */
export type PapiProviderProfile = {
  __typename?: 'PapiProviderProfile';
  id: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  photoURL?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['JSON']['output']>;
  biography?: Maybe<Array<Maybe<PapiProviderProfileBiography>>>;
  role?: Maybe<Scalars['String']['output']>;
};

export enum PapiQuestionnarieStatusFilter {
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED'
}

export enum PapiQuestionnarieTypeFilter {
  SYMPTOM_TRACKER = 'SYMPTOM_TRACKER',
  VISIT_PREP = 'VISIT_PREP',
  FOOD_DIARY = 'FOOD_DIARY',
  HISTORICAL = 'HISTORICAL',
  OTHER = 'OTHER'
}

export enum PapiQuestionnarieVisitFilter {
  MEDICAL_VISIT = 'MEDICAL_VISIT',
  HEALTH_COACH_VISIT = 'HEALTH_COACH_VISIT',
  LAB_DRAW = 'LAB_DRAW',
  CARE_MANAGER_CHECK_IN = 'CARE_MANAGER_CHECK_IN',
  NO_VISIT = 'NO_VISIT'
}

export type SanityCancellationOptions = {
  __typename?: 'SanityCancellationOptions';
  _id: Scalars['ID']['output'];
  copy?: Maybe<Scalars['String']['output']>;
  cta?: Maybe<SanityCancellationCta>;
  imageSrc?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SanityCancellationCta = {
  __typename?: 'SanityCancellationCTA';
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
};

export type SanitySlugItem = {
  __typename?: 'SanitySlugItem';
  noindex?: Maybe<Scalars['Boolean']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  imageSrc?: Maybe<Scalars['String']['output']>;
  _rev?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  sanityTitle?: Maybe<Scalars['String']['output']>;
  _id?: Maybe<Scalars['String']['output']>;
  metaImage?: Maybe<PapiMetaImage>;
  slug?: Maybe<PapiSlug>;
  productionReady?: Maybe<Scalars['Boolean']['output']>;
  _createdAt?: Maybe<Scalars['String']['output']>;
  _updatedAt?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
};

export type PapiMetaImage = {
  __typename?: 'PapiMetaImage';
  _type?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<PapiAsset>;
};

export type PapiAsset = {
  __typename?: 'PapiAsset';
  _ref?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
};

export type PapiSlug = {
  __typename?: 'PapiSlug';
  current?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
};

export type SanityVisitInfoPage = {
  __typename?: 'SanityVisitInfoPage';
  _id: Scalars['ID']['output'];
  imageSrc?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Array<Maybe<SanityVisitInfoContent>>>;
};

export type SanityVisitInfoContent = {
  __typename?: 'SanityVisitInfoContent';
  visitState?: Maybe<Scalars['String']['output']>;
  subHeading?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['ID']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  preview: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  threads: Array<Thread>;
  urgent: Scalars['Boolean']['output'];
  unread: Scalars['Boolean']['output'];
  participants: Array<ThreadCreatedBy>;
  assignedProviderHelpScoutID?: Maybe<Scalars['ID']['output']>;
  assignee?: Maybe<Assignee>;
  userUpdatedAt: Scalars['String']['output'];
  customerWaitingSince?: Maybe<CustomerWaitingSince>;
  status: Scalars['String']['output'];
  customerID: Scalars['ID']['output'];
  assignedProvider?: Maybe<PapiProviderProfile>;
};

export type Thread = {
  __typename?: 'Thread';
  id: Scalars['ID']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: ThreadCreatedBy;
};

export type ThreadCreatedBy = {
  __typename?: 'ThreadCreatedBy';
  id: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  photoURL?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  providerProfile?: Maybe<PapiProviderProfile>;
};

export type Assignee = {
  __typename?: 'Assignee';
  id: Scalars['ID']['output'];
  first: Scalars['String']['output'];
  last: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
};

export type CustomerWaitingSince = {
  __typename?: 'CustomerWaitingSince';
  time?: Maybe<Scalars['String']['output']>;
  friendly?: Maybe<Scalars['String']['output']>;
};

export enum ConversationStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ALL = 'ALL'
}

export type ConversationsResult = {
  __typename?: 'ConversationsResult';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['String']['output']>;
  records: Array<Conversation>;
};

export type MessageTopic = {
  __typename?: 'MessageTopic';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  recipient: MessageRecipient;
  recipientProfile?: Maybe<PapiProviderProfile>;
};

export enum MessageRecipient {
  CARE_MANAGER = 'CARE_MANAGER',
  MEMBER_EXPERIENCE = 'MEMBER_EXPERIENCE',
  MEMBER_ADVOCATE = 'MEMBER_ADVOCATE',
  HEALTH_COACH = 'HEALTH_COACH',
  DOCTOR = 'DOCTOR',
  FRONT_OF_HOUSE = 'FRONT_OF_HOUSE',
  BILLING = 'BILLING',
  STORE_OPS = 'STORE_OPS',
  CLINICAL_COORDINATOR = 'CLINICAL_COORDINATOR',
  REGISTERED_NURSE = 'REGISTERED_NURSE'
}

export type ImawarePatient = {
  __typename?: 'ImawarePatient';
  id: Scalars['ID']['output'];
  active: Scalars['Boolean']['output'];
  address: Address;
  birthDate: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: AdministrativeGender;
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export enum AdministrativeGender {
  FEMALE = 'female',
  MALE = 'male'
}

export type Person = {
  __typename?: 'Person';
  id: Scalars['ID']['output'];
  isActivePartnerEmployee: Scalars['Boolean']['output'];
  partner?: Maybe<Partner>;
  emrID: Scalars['String']['output'];
  bannerNames: Scalars['String']['output'];
  mdhqInstance: Scalars['String']['output'];
  medicationsV2: Array<MedicationV2>;
  supplementsV2: Array<SupplementV2>;
  patientV2: PatientV2;
  patientTagsV2: PatientTagV2Result;
  visitNotesV2: Array<VisitNoteV2>;
  visitNoteV2?: Maybe<VisitNoteV2>;
  adtV2: Adtv2;
  documentV2?: Maybe<DocumentV2>;
  documentsV2: Array<DocumentV2>;
  documentsByFoldersV2: Array<DocumentV2>;
  labDoctorSelectedTests: VitalTestPanelConnection;
  labDoctorSelectedPanels: VitalTestPanelConnection;
  labOtherOrderableTests: VitalTestPanelConnection;
  labPreorderedTests: VitalTestPanelConnection;
  labOrders: LabOrderConnection;
  labResults: LabResultConnection;
  consolidatedLabResults?: Maybe<Array<ConsolidatedLabResult>>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  genderIdentity?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
};


export type PersonPatientTagsV2Args = {
  filter?: InputMaybe<PatientTagV2Filter>;
};


export type PersonVisitNoteV2Args = {
  id: Scalars['String']['input'];
};


export type PersonDocumentV2Args = {
  id: Scalars['ID']['input'];
  object?: InputMaybe<Scalars['String']['input']>;
};


export type PersonDocumentsV2Args = {
  category: Scalars['String']['input'];
  filter?: InputMaybe<DocumentV2Filter>;
};


export type PersonDocumentsByFoldersV2Args = {
  folders: Array<Scalars['String']['input']>;
  filter?: InputMaybe<DocumentV2Filter>;
};


export type PersonLabDoctorSelectedTestsArgs = {
  filter?: InputMaybe<MdhqOrderItemFilter>;
};


export type PersonLabDoctorSelectedPanelsArgs = {
  filter?: InputMaybe<MdhqOrderGroupFilter>;
};


export type PersonLabOtherOrderableTestsArgs = {
  filter?: InputMaybe<VitalTestPanelFilter>;
};


export type PersonLabPreorderedTestsArgs = {
  filter?: InputMaybe<LabPreorderItemFilter>;
};


export type PersonLabOrdersArgs = {
  filter?: InputMaybe<LabOrderFilter>;
};


export type PersonLabResultsArgs = {
  filter?: InputMaybe<LabResultFilter>;
};


export type PersonConsolidatedLabResultsArgs = {
  filter?: InputMaybe<LabResultFilter>;
};

export type MdhqOrderItemFilter = {
  order?: InputMaybe<Array<OrderField>>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type OrderField = {
  field?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<OrderDirection>;
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type VitalTestPanelConnection = {
  __typename?: 'VitalTestPanelConnection';
  edges: Array<VitalTestPanelEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VitalTestPanelEdge = {
  __typename?: 'VitalTestPanelEdge';
  cursor: Scalars['UUID']['output'];
  node?: Maybe<VitalTestPanel>;
};

export type VitalTestPanel = {
  __typename?: 'VitalTestPanel';
  id: Scalars['UUID']['output'];
  mdhqOrderItemSource?: Maybe<MdhqOrderItemSource>;
  mdhqOrderGroupSource?: Maybe<MdhqOrderGroupSource>;
  labPreorderItemSource?: Maybe<LabPreorderItemSource>;
  displayName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  priceInCents: Scalars['Int']['output'];
  markers: Array<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type MdhqOrderItemSource = {
  __typename?: 'MDHQOrderItemSource';
  id: Scalars['UUID']['output'];
  encounterNoteId?: Maybe<Scalars['String']['output']>;
};

export type MdhqOrderGroupSource = {
  __typename?: 'MDHQOrderGroupSource';
  id: Scalars['UUID']['output'];
  encounterNoteId?: Maybe<Scalars['String']['output']>;
};

export type LabPreorderItemSource = {
  __typename?: 'LabPreorderItemSource';
  id: Scalars['UUID']['output'];
  labOrderItemID?: Maybe<Scalars['UUID']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['Cursor']['output']>;
  endCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type MdhqOrderGroupFilter = {
  order?: InputMaybe<Array<OrderField>>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type VitalTestPanelFilter = {
  order?: InputMaybe<Array<OrderField>>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type LabPreorderItemFilter = {
  order?: InputMaybe<Array<OrderField>>;
  pagination?: InputMaybe<PaginationRequest>;
  purchaseID?: InputMaybe<Scalars['ID']['input']>;
  purchasePeriodID?: InputMaybe<Scalars['ID']['input']>;
  includeAlreadyOrdered?: InputMaybe<Scalars['Boolean']['input']>;
  dedupe?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabOrderFilter = {
  dateRange?: InputMaybe<DateRange>;
  order?: InputMaybe<Array<OrderField>>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type DateRange = {
  startDate: Scalars['Date']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
};

export type LabOrderConnection = {
  __typename?: 'LabOrderConnection';
  edges: Array<LabOrderEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabOrderEdge = {
  __typename?: 'LabOrderEdge';
  cursor: Scalars['UUID']['output'];
  node?: Maybe<LabOrder>;
};

export type LabOrder = {
  __typename?: 'LabOrder';
  id: Scalars['UUID']['output'];
  items: LabOrderItemConnection;
  areAllRequisitionsReady: Scalars['Boolean']['output'];
  isRequisitionReady: Scalars['Boolean']['output'];
  createdAt: Scalars['Time']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type LabOrderItemsArgs = {
  filter?: InputMaybe<LabOrderItemFilter>;
};

export type LabOrderItemFilter = {
  order?: InputMaybe<Array<OrderField>>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type LabOrderItemConnection = {
  __typename?: 'LabOrderItemConnection';
  edges: Array<LabOrderItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabOrderItemEdge = {
  __typename?: 'LabOrderItemEdge';
  cursor: Scalars['UUID']['output'];
  node?: Maybe<LabOrderItem>;
};

export type LabOrderItem = {
  __typename?: 'LabOrderItem';
  id: Scalars['UUID']['output'];
  status: LabOrderItemState;
  details?: Maybe<VitalTestPanel>;
  isRequisitionReady: Scalars['Boolean']['output'];
  createdAt: Scalars['Time']['output'];
  updatedAt: Scalars['Time']['output'];
};

export enum LabOrderItemState {
  UNSTARTED = 'UNSTARTED',
  ORDERED = 'ORDERED',
  READY = 'READY',
  PROCESSING_RESULTS = 'PROCESSING_RESULTS',
  PENDING_REVIEW = 'PENDING_REVIEW',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED'
}

export type LabResultFilter = {
  markers?: InputMaybe<Array<Scalars['String']['input']>>;
  dateRange?: InputMaybe<DateRange>;
  order?: InputMaybe<Array<OrderField>>;
  pagination?: InputMaybe<PaginationRequest>;
};

export type LabResultConnection = {
  __typename?: 'LabResultConnection';
  edges: Array<LabResultEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabResultEdge = {
  __typename?: 'LabResultEdge';
  cursor: Scalars['UUID']['output'];
  node?: Maybe<LabResult>;
};

export type LabResult = {
  __typename?: 'LabResult';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  units: Scalars['String']['output'];
  range: LabRangeDetail;
  value: Scalars['String']['output'];
  valueState: LabValueState;
  severity: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionKey: Scalars['String']['output'];
  labName: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  collectedAt: Scalars['Time']['output'];
  reportedAt?: Maybe<Scalars['Time']['output']>;
  updatedAt: Scalars['Time']['output'];
};

export type LabRangeDetail = {
  __typename?: 'LabRangeDetail';
  lowerLimit?: Maybe<Scalars['String']['output']>;
  upperLimit?: Maybe<Scalars['String']['output']>;
  operator: LabRangeOperator;
};

export enum LabRangeOperator {
  LT = 'LT',
  LTE = 'LTE',
  GT = 'GT',
  GTE = 'GTE',
  BETWEEN = 'BETWEEN',
  NEGATIVE = 'NEGATIVE',
  NEGATIVE_LTE = 'NEGATIVE_LTE',
  POSITIVE = 'POSITIVE',
  UNKNOWN = 'UNKNOWN'
}

export enum LabValueState {
  NORMAL = 'NORMAL',
  LOW_MILD = 'LOW_MILD',
  LOW_SEVERE = 'LOW_SEVERE',
  HIGH_MILD = 'HIGH_MILD',
  HIGH_SEVERE = 'HIGH_SEVERE',
  UNKNOWN = 'UNKNOWN'
}

export type ConsolidatedLabResult = {
  __typename?: 'ConsolidatedLabResult';
  marker: Scalars['String']['output'];
  results?: Maybe<Array<LabResult>>;
};

export type QuestionnaireConfigCache = {
  __typename?: 'QuestionnaireConfigCache';
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  config: Scalars['String']['output'];
};

export type QuestionnaireSetting = {
  __typename?: 'QuestionnaireSetting';
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createPartnerEmployee: CreatePartnerEmployeeResponse;
  deletePartnerAttributes?: Maybe<DeletePartnerAttributesResponse>;
  upsertPartner: UpsertPartnerResponse;
  commitPendingPartnerEmployees: CommitPendingPartnerEmployeesResponse;
  uploadLabV2: Scalars['Boolean']['output'];
  uploadDocumentsV2: Scalars['Boolean']['output'];
  uploadMemberPhotoIDV2: Scalars['Boolean']['output'];
  uploadDocumentsWithReviewerV2: Scalars['Boolean']['output'];
  createConversationV2: Scalars['ID']['output'];
  replyConversation: ConversationMessageV2;
  markConversationReadV2: ConversationV2;
  updateConversationTopic: Array<ConversationTopic>;
  createEncounterV2: EncounterV2;
  deleteEncounterV2: Scalars['Boolean']['output'];
  eligibilityCheck: EligibilityCheckResult;
  addMedicationV2: MedicationV2;
  discontinueMedicationV2: MedicationV2;
  triggerMigrateMedication: MigrateMedicationResult;
  exportMedication: ExportMedicationResult;
  messagingMigrationUpdateStatus: MessagingMigrationUpdateResult;
  encounterMigrationUpdateStatus: EncounterMigrationUpdateResult;
  documentMigrationUpdateStatus: DocumentMigrationUpdateResult;
  addPatientV2: PatientV2;
  updatePatientV2: PatientV2;
  upsertPatientTagsV2: Scalars['Boolean']['output'];
  upsertPatientCustomPropertiesV2: PatientCustomPropertiesV2;
  updatePerson: Scalars['Boolean']['output'];
  addEmrProvider: Provider;
  updateEmrProvider: Provider;
  batchUpdateEmrProvidersSetting: BatchUpdateEmrProvidersSettingResult;
  upsertSetting: Setting;
  addSupplementV2: SupplementV2;
  discontinueSupplementV2: SupplementV2;
  createLabOrder?: Maybe<LabOrder>;
  createInsuranceLabOrder?: Maybe<LabOrder>;
  cancelLabOrder?: Maybe<LabOrder>;
  unblockAppointment: PapiAppointment;
  markAppointmentAsNoShow: PapiAppointment;
  scheduleAppointment: PapiAppointment;
  rescheduleAppointment: PapiAppointment;
  cancelAppointment: PapiAppointment;
  createAppointment: PapiAppointment;
  deleteAppointment: PapiAppointment;
  updateAppointment: PapiAppointment;
  createEncounterNote: PapiAppointment;
  addCouponToPurchase: PapiPlanPurchase;
  createCoupon?: Maybe<PapiCoupon>;
  addInsuranceSubscriber: PapiInsuranceSubscriber;
  updateInsuranceSubscriber: PapiInsuranceSubscriber;
  setInvoicePaymentStatus: PapiInvoice;
  storeLeadData: PapiLeadDataResponse;
  signAgreement: Scalars['Boolean']['output'];
  registerPaymentCardWithPerson: PapiPaymentCard;
  setDefaultPaymentCard: PapiPaymentCard;
  updateCoupon: PapiCoupon;
  deletePaymentCard: Scalars['Boolean']['output'];
  attachPaymentMethod: Scalars['Boolean']['output'];
  createPaymentSetupIntent: PapiCreateSetupIntentResponse;
  setDefaultPaymentMethod: Scalars['Boolean']['output'];
  deleteCardPaymentMethod: Scalars['Boolean']['output'];
  createPersonEmergencyContact: PapiPersonEmergencyContact;
  updatePersonEmergencyContact: PapiPersonEmergencyContact;
  createPapiPerson: PapiPerson;
  updatePapiPerson: PapiPerson;
  deletePapiPerson?: Maybe<PapiDeleteResponse>;
  deletePapiPersonFirebaseProvider?: Maybe<Scalars['String']['output']>;
  updatePersonOnboardingCompletedAt?: Maybe<PapiPerson>;
  adminResetPassword: Scalars['Boolean']['output'];
  updateCurrentPapiPerson: PapiPerson;
  rectifyThirdParties?: Maybe<PapiRectifyThirdPartiesPayload>;
  updateInsurance: PapiPerson;
  declineInsurance: PapiPerson;
  unassignProvider: PapiPerson;
  createInsightsUser?: Maybe<PapiInsightUser>;
  transferCare?: Maybe<PapiPerson>;
  transitionToInsurancePurchase: PapiPlanPurchase;
  transitionFromInsurancePurchase: PapiPlanPurchase;
  makeNewPurchase: PapiMakeNewPurchasePayload;
  makeNewB2BPurchase: PapiMakeNewPurchasePayload;
  cancelPurchase?: Maybe<PapiPlanPurchase>;
  changeFollowOnPlan: PapiPlanPurchase;
  modifyFollowOnPlan: PapiPlanPurchase;
  extendPurchase: PapiPlanPurchase;
  addFollowOnPlan: PapiPlanPurchase;
  changePlanImmediately: PapiPlanPurchase;
  changePlanAtCommitmentEnd: PapiPlanPurchase;
  enrollFromWaitlist: PapiPlanPurchase;
  pausePurchase: PapiPlanPurchase;
  createPlan: PapiPlan;
  initiateOneTimePurchase: PapiInitiateOneTimePurchaseResponse;
  finalizeOneTimePurchase: PapiPlanPurchase;
  updatePlanJoinableCenters: PapiPlan;
  updateProviderIsMedicareOptedOut: Scalars['Boolean']['output'];
  updateProviderAttr?: Maybe<Array<PapiProviderAttr>>;
  /** Assigns a care manager to a clinician, see {Provider.assignedCareManager} and {Mutation.unAssignCareManagerFromClinician} */
  assignCareManagerToClinician?: Maybe<PapiProvider>;
  /** Removes a care manager from a clinician, see {Provider.assignedCareManager} and {Mutation.assignCareManagerToClinician} */
  unAssignCareManagerFromClinician?: Maybe<PapiProvider>;
  updateProvider: PapiProvider;
  addProvider: PapiProvider;
  upsertProviderCenter: PapiProvider;
  clearCacheForProvider: Scalars['Boolean']['output'];
  /** @deprecated Use updateProvider */
  updateProviderWithCenters: PapiProvider;
  /** @deprecated Use addProvider */
  addProviderWithCenters: PapiProvider;
  deleteProvider: Scalars['Boolean']['output'];
  assignProviderToPerson: Scalars['Boolean']['output'];
  unassignProviderFromPerson: Scalars['Boolean']['output'];
  addProviderPayerCredentials: PapiProvider;
  removeProviderPayerCredentials: PapiProvider;
  acceptQuestionnaires: Array<PapiAcceptQuestionnairesResponse>;
  createQuestionnaire: PapiQuestionnaire;
  submitQuestionnaire: PapiQuestionnaire;
  updateQuestionnaire: PapiQuestionnaire;
  createConversation: Scalars['ID']['output'];
  createThread: Conversation;
  /** @deprecated Use markConversationRead */
  markRead: Conversation;
  markConversationRead: Conversation;
  createImawarePatient: ImawarePatient;
  uploadLab: Scalars['Boolean']['output'];
  uploadDocuments: Scalars['Boolean']['output'];
  uploadDocumentsWithReviewer: Scalars['Boolean']['output'];
  uploadMemberPhotoID: Scalars['Boolean']['output'];
  addMedication: Medication;
  addSupplement: Supplement;
  chatGptCompletion: ChatCompletionResponse;
  startQuestionnaire: Questionnaire;
  evaluateQuestionnaireForm: QuestionnaireForm;
  submitQuestionnaireForm?: Maybe<Array<QuestionnaireSection>>;
  upsertQuestionnaireConfigCache: QuestionnaireConfigCache;
  upsertQuestionnaireSetting: QuestionnaireSetting;
};


export type MutationCreatePartnerEmployeeArgs = {
  employee: EmployeeEligibilityInfo;
  agreements: Array<AgreementType>;
  answers?: InputMaybe<Array<Answer>>;
  rulesetID?: InputMaybe<Scalars['ID']['input']>;
  employeeAttrs?: InputMaybe<Array<Attribute>>;
};


export type MutationDeletePartnerAttributesArgs = {
  partner: DeletePartnerAttributesRequest;
};


export type MutationUpsertPartnerArgs = {
  partner: UpsertPartnerRequest;
};


export type MutationCommitPendingPartnerEmployeesArgs = {
  request: CommitPendingPartnerEmployeesRequest;
};


export type MutationUploadLabV2Args = {
  file: FileV2;
};


export type MutationUploadDocumentsV2Args = {
  files: Array<FileV2>;
  folder?: InputMaybe<Scalars['String']['input']>;
  enqueue?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUploadMemberPhotoIdv2Args = {
  file: FileV2;
  folder?: InputMaybe<Scalars['String']['input']>;
  enqueue?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUploadDocumentsWithReviewerV2Args = {
  files: Array<FileV2>;
  folder?: InputMaybe<Scalars['String']['input']>;
  subfolder?: InputMaybe<Scalars['String']['input']>;
  reviewerID?: InputMaybe<Scalars['Int']['input']>;
  enqueue?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateConversationV2Args = {
  input: CreateConversationInput;
};


export type MutationReplyConversationArgs = {
  input: ReplyConversationInput;
};


export type MutationMarkConversationReadV2Args = {
  conversationID: Scalars['ID']['input'];
};


export type MutationUpdateConversationTopicArgs = {
  input: ConversationTopicUpdate;
};


export type MutationCreateEncounterV2Args = {
  input: NewEncounterV2;
};


export type MutationDeleteEncounterV2Args = {
  encounterID: Scalars['ID']['input'];
};


export type MutationEligibilityCheckArgs = {
  input: EligibilityCheckInput;
};


export type MutationAddMedicationV2Args = {
  input: NewMedicationV2;
};


export type MutationDiscontinueMedicationV2Args = {
  input: DiscontinueMedicationV2;
};


export type MutationTriggerMigrateMedicationArgs = {
  input?: InputMaybe<MigrateMedication>;
};


export type MutationExportMedicationArgs = {
  input?: InputMaybe<ExportMedication>;
};


export type MutationMessagingMigrationUpdateStatusArgs = {
  input?: InputMaybe<MigrationUpdateStatus>;
};


export type MutationEncounterMigrationUpdateStatusArgs = {
  input?: InputMaybe<MigrationUpdateStatus>;
};


export type MutationDocumentMigrationUpdateStatusArgs = {
  input?: InputMaybe<MigrationUpdateStatus>;
};


export type MutationAddPatientV2Args = {
  input: PatientUpsertV2;
};


export type MutationUpdatePatientV2Args = {
  patientID: Scalars['ID']['input'];
  input: PatientUpsertV2;
};


export type MutationUpsertPatientTagsV2Args = {
  patientID: Scalars['ID']['input'];
  input: Array<PatientTagUpsertV2>;
};


export type MutationUpsertPatientCustomPropertiesV2Args = {
  patientID: Scalars['ID']['input'];
  input: PatientCustomPropertiesUpsertV2;
};


export type MutationUpdatePersonArgs = {
  input: UpdatePersonV2;
};


export type MutationAddEmrProviderArgs = {
  input: AddEmrProviderInput;
};


export type MutationUpdateEmrProviderArgs = {
  providerID: Scalars['ID']['input'];
  input: UpdateEmrProviderInput;
};


export type MutationBatchUpdateEmrProvidersSettingArgs = {
  input: BatchUpdateEmrProvidersSettingInput;
};


export type MutationUpsertSettingArgs = {
  input: SettingUpsertInput;
};


export type MutationAddSupplementV2Args = {
  input: NewSupplementV2;
};


export type MutationDiscontinueSupplementV2Args = {
  input: DiscontinueSupplementV2;
};


export type MutationCreateLabOrderArgs = {
  input: LabOrderInput;
};


export type MutationCreateInsuranceLabOrderArgs = {
  input: InsuranceLabOrderInput;
};


export type MutationCancelLabOrderArgs = {
  input: LabOrderInput;
};


export type MutationUnblockAppointmentArgs = {
  appointmentID: Scalars['ID']['input'];
};


export type MutationMarkAppointmentAsNoShowArgs = {
  appointmentID: Scalars['ID']['input'];
};


export type MutationScheduleAppointmentArgs = {
  input: PapiScheduleAppointmentInput;
};


export type MutationRescheduleAppointmentArgs = {
  input: PapiRescheduleAppointmentInput;
};


export type MutationCancelAppointmentArgs = {
  appointmentID: Scalars['ID']['input'];
};


export type MutationCreateAppointmentArgs = {
  input: PapiCreateAppointmentInput;
};


export type MutationDeleteAppointmentArgs = {
  appointmentID: Scalars['ID']['input'];
};


export type MutationUpdateAppointmentArgs = {
  input: PapiUpdateAppointmentInput;
};


export type MutationCreateEncounterNoteArgs = {
  appointmentID: Scalars['ID']['input'];
};


export type MutationAddCouponToPurchaseArgs = {
  input: PapiAddCouponToPurchaseInput;
};


export type MutationCreateCouponArgs = {
  input: PapiCreateCouponInput;
};


export type MutationAddInsuranceSubscriberArgs = {
  input: PapiAddInsuranceSubscriberInput;
};


export type MutationUpdateInsuranceSubscriberArgs = {
  input: PapiUpdateInsuranceSubscriberInput;
};


export type MutationSetInvoicePaymentStatusArgs = {
  input: PapiSetInvoicePaymentStatusInput;
};


export type MutationStoreLeadDataArgs = {
  input: PapiLeadDataInput;
};


export type MutationSignAgreementArgs = {
  input: PapiSignAgreementInput;
};


export type MutationRegisterPaymentCardWithPersonArgs = {
  input: PapiRegisterPaymentCardWithPersonInput;
};


export type MutationSetDefaultPaymentCardArgs = {
  paymentCardID: Scalars['ID']['input'];
};


export type MutationUpdateCouponArgs = {
  input: PapiUpdateCouponInput;
};


export type MutationDeletePaymentCardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAttachPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
  personId: Scalars['ID']['input'];
};


export type MutationCreatePaymentSetupIntentArgs = {
  personId: Scalars['ID']['input'];
};


export type MutationSetDefaultPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
  personId: Scalars['ID']['input'];
};


export type MutationDeleteCardPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
  personId: Scalars['ID']['input'];
};


export type MutationCreatePersonEmergencyContactArgs = {
  input: PapiPersonEmergencyContactInput;
};


export type MutationUpdatePersonEmergencyContactArgs = {
  input: PapiPersonEmergencyContactInput;
};


export type MutationCreatePapiPersonArgs = {
  input: PapiCreatePersonInput;
};


export type MutationUpdatePapiPersonArgs = {
  input: PapiUpdatePersonInput;
};


export type MutationDeletePapiPersonArgs = {
  personID: Scalars['ID']['input'];
};


export type MutationDeletePapiPersonFirebaseProviderArgs = {
  personID: Scalars['ID']['input'];
};


export type MutationUpdatePersonOnboardingCompletedAtArgs = {
  personID: Scalars['ID']['input'];
  onboardingCompletedAt: Scalars['String']['input'];
};


export type MutationAdminResetPasswordArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type MutationUpdateCurrentPapiPersonArgs = {
  input: PapiUpdateCurrentPapiPersonInput;
};


export type MutationRectifyThirdPartiesArgs = {
  personID: Scalars['ID']['input'];
};


export type MutationUpdateInsuranceArgs = {
  input: PapiUpdateInsuranceInput;
};


export type MutationDeclineInsuranceArgs = {
  personID: Scalars['ID']['input'];
};


export type MutationUnassignProviderArgs = {
  personID: Scalars['ID']['input'];
  providerID: Scalars['ID']['input'];
};


export type MutationCreateInsightsUserArgs = {
  input: PapiCreateInsightsUserInput;
};


export type MutationTransferCareArgs = {
  input: PapiTransferCareInput;
};


export type MutationTransitionToInsurancePurchaseArgs = {
  input: PapiTransitionPlanPurchaseInput;
};


export type MutationTransitionFromInsurancePurchaseArgs = {
  input: PapiTransitionPlanPurchaseInput;
};


export type MutationMakeNewPurchaseArgs = {
  input: PapiMakeNewPurchaseInput;
};


export type MutationMakeNewB2BPurchaseArgs = {
  input: PapiMakeNewB2BPurchaseInput;
};


export type MutationCancelPurchaseArgs = {
  input: PapiCancelPurchaseInput;
};


export type MutationChangeFollowOnPlanArgs = {
  input: PapiChangeFollowOnPlanInput;
};


export type MutationModifyFollowOnPlanArgs = {
  input: PapiModifyFollowOnPlanInput;
};


export type MutationExtendPurchaseArgs = {
  input: PapiExtendPurchaseInput;
};


export type MutationAddFollowOnPlanArgs = {
  input: PapiAddFollowOnPlanInput;
};


export type MutationChangePlanImmediatelyArgs = {
  input: PapiChangePlanImmediatelyInput;
};


export type MutationChangePlanAtCommitmentEndArgs = {
  input: PapiChangePlanAtCommitmentEnd;
};


export type MutationEnrollFromWaitlistArgs = {
  input: PapiEnrollFromWaitlistInput;
};


export type MutationPausePurchaseArgs = {
  input: PapiPausePurchaseInput;
};


export type MutationCreatePlanArgs = {
  input: PapiCreatePlanInput;
};


export type MutationInitiateOneTimePurchaseArgs = {
  input: PapiOneTimePurchaseInput;
};


export type MutationFinalizeOneTimePurchaseArgs = {
  input: PapiFinalizeOneTimePurchaseInput;
};


export type MutationUpdatePlanJoinableCentersArgs = {
  input: PapiUpdatePlanJoinableCentersInput;
};


export type MutationUpdateProviderIsMedicareOptedOutArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationUpdateProviderAttrArgs = {
  input: Array<PapiProviderAttrInput>;
};


export type MutationAssignCareManagerToClinicianArgs = {
  clinicianID: Scalars['ID']['input'];
  careManagerID: Scalars['ID']['input'];
};


export type MutationUnAssignCareManagerFromClinicianArgs = {
  clinicianID: Scalars['ID']['input'];
};


export type MutationUpdateProviderArgs = {
  input: PapiUpdateProviderInput;
};


export type MutationAddProviderArgs = {
  input: PapiAddProviderInput;
};


export type MutationUpsertProviderCenterArgs = {
  input: PapiUpsertProviderCenterInput;
};


export type MutationClearCacheForProviderArgs = {
  providerID: Scalars['ID']['input'];
};


export type MutationUpdateProviderWithCentersArgs = {
  input: PapiUpdateProviderWithCentersInput;
};


export type MutationAddProviderWithCentersArgs = {
  input: PapiAddProviderWithCentersInput;
};


export type MutationDeleteProviderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAssignProviderToPersonArgs = {
  personID: Scalars['ID']['input'];
  providerID: Scalars['ID']['input'];
};


export type MutationUnassignProviderFromPersonArgs = {
  personID: Scalars['ID']['input'];
  providerID: Scalars['ID']['input'];
};


export type MutationAddProviderPayerCredentialsArgs = {
  input: PapiAddProviderPayerCredentialsInput;
};


export type MutationRemoveProviderPayerCredentialsArgs = {
  input: PapiRemoveProviderPayerCredentialsInput;
};


export type MutationAcceptQuestionnairesArgs = {
  input: PapiAcceptQuestionnairesInput;
};


export type MutationCreateQuestionnaireArgs = {
  input: PapiCreateQuestionnaireInput;
};


export type MutationSubmitQuestionnaireArgs = {
  input: PapiSubmitQuestionnaireInput;
};


export type MutationUpdateQuestionnaireArgs = {
  input: PapiUpdateQuestionnaireInput;
};


export type MutationCreateConversationArgs = {
  topicID: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateThreadArgs = {
  conversationID: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};


export type MutationMarkReadArgs = {
  conversationID: Scalars['ID']['input'];
  threadID: Scalars['ID']['input'];
};


export type MutationMarkConversationReadArgs = {
  conversationID: Scalars['ID']['input'];
};


export type MutationCreateImawarePatientArgs = {
  input: CreateImawarePatientInput;
};


export type MutationUploadLabArgs = {
  file: File;
};


export type MutationUploadDocumentsArgs = {
  files: Array<File>;
  folder?: InputMaybe<Scalars['String']['input']>;
  enqueue?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUploadDocumentsWithReviewerArgs = {
  files: Array<File>;
  folder?: InputMaybe<Scalars['String']['input']>;
  subfolder?: InputMaybe<Scalars['String']['input']>;
  reviewerID?: InputMaybe<Scalars['Int']['input']>;
  enqueue?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUploadMemberPhotoIdArgs = {
  file: File;
  folder?: InputMaybe<Scalars['String']['input']>;
  enqueue?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationAddMedicationArgs = {
  ID: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddSupplementArgs = {
  ID: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChatGptCompletionArgs = {
  input: ChatGptInput;
};


export type MutationStartQuestionnaireArgs = {
  type: Scalars['String']['input'];
};


export type MutationEvaluateQuestionnaireFormArgs = {
  input: EvaluateQuestionnaireFormInput;
};


export type MutationSubmitQuestionnaireFormArgs = {
  input: SubmitQuestionnaireFormInput;
};


export type MutationUpsertQuestionnaireConfigCacheArgs = {
  input: QuestionnaireConfigCacheInput;
};


export type MutationUpsertQuestionnaireSettingArgs = {
  input: QuestionnaireSettingInput;
};

export type EmployeeEligibilityInfo = {
  partnerSlug: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  preferredEmail: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  biologicalSex?: InputMaybe<BiologicalSex>;
  alternateID?: InputMaybe<Scalars['String']['input']>;
  groupID?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum AgreementType {
  CLINICAL_MEMBERSHIP_AGREEMENT = 'CLINICAL_MEMBERSHIP_AGREEMENT',
  TERMS_OF_USE = 'TERMS_OF_USE',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TELEHEALTH_INFORMED_CONSENT = 'TELEHEALTH_INFORMED_CONSENT',
  NOTICE_PRIVACY_PRACTICES = 'NOTICE_PRIVACY_PRACTICES'
}

/**
 * selections for single/multi select
 * value for text reply
 */
export type Answer = {
  questionID: Scalars['ID']['input'];
  selections?: InputMaybe<Array<Scalars['ID']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Attribute = {
  attr: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CreatePartnerEmployeeResponse = {
  __typename?: 'CreatePartnerEmployeeResponse';
  eligibilityStatus: EligibilityStatus;
  eligiblePartnerEmployee?: Maybe<PartnerEmployee>;
};

export enum EligibilityStatus {
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  ALREADY_ENROLLED = 'ALREADY_ENROLLED',
  OUTSIDE_COVERAGE_PERIOD = 'OUTSIDE_COVERAGE_PERIOD'
}

export type DeletePartnerAttributesRequest = {
  partnerID: Scalars['ID']['input'];
  attributes: Array<Scalars['String']['input']>;
};

export type DeletePartnerAttributesResponse = {
  __typename?: 'DeletePartnerAttributesResponse';
  partnerID: Scalars['ID']['output'];
  affectedRows: Scalars['Int']['output'];
};

export type UpsertPartnerRequest = {
  id?: InputMaybe<Scalars['ID']['input']>;
  attributes?: InputMaybe<Array<InputPartnerAttr>>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  launchDate?: InputMaybe<Scalars['Date']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  parentID?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PartnerStatus>;
};

export type InputPartnerAttr = {
  attr: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertPartnerResponse = {
  __typename?: 'UpsertPartnerResponse';
  partner?: Maybe<Partner>;
  status: UpsertPartnerStatus;
};

export enum UpsertPartnerStatus {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type CommitPendingPartnerEmployeesRequest = {
  action: CommitPendingPartnerEmployeeAction;
  partnerSlug: Scalars['String']['input'];
};

export enum CommitPendingPartnerEmployeeAction {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT'
}

export type CommitPendingPartnerEmployeesResponse = {
  __typename?: 'CommitPendingPartnerEmployeesResponse';
  status: CommitPendingPartnerEmployeeStatus;
};

export enum CommitPendingPartnerEmployeeStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export type FileV2 = {
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  base64Content: Scalars['String']['input'];
};

export type CreateConversationInput = {
  topic: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  content: Scalars['String']['input'];
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
};

export type ReplyConversationInput = {
  conversationID: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type ConversationTopicUpdate = {
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
  is_clinical?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  support_group?: InputMaybe<Scalars['String']['input']>;
  coordinators: Array<Scalars['String']['input']>;
};

export type NewEncounterV2 = {
  title: Scalars['String']['input'];
  dateOfService?: InputMaybe<Scalars['String']['input']>;
  encounterType: Scalars['String']['input'];
};

export type EncounterV2 = {
  __typename?: 'EncounterV2';
  object: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ptId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  shareParentPlan: Scalars['String']['output'];
  signedBy?: Maybe<Scalars['String']['output']>;
  dateSigned?: Maybe<Scalars['String']['output']>;
  cosigned: Scalars['String']['output'];
  dateCosigned?: Maybe<Scalars['String']['output']>;
  owner: Scalars['String']['output'];
  addedBy: Scalars['String']['output'];
  encounterType: Scalars['String']['output'];
  dateOfService: Scalars['String']['output'];
  isSigned?: Maybe<Scalars['Boolean']['output']>;
  lastUpdated: Scalars['String']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  created: Scalars['String']['output'];
};

export type EligibilityCheckInput = {
  state: Scalars['String']['input'];
  payerId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  dob: Scalars['String']['input'];
  memberId?: InputMaybe<Scalars['String']['input']>;
};

export type EligibilityCheckResult = {
  __typename?: 'EligibilityCheckResult';
  status: EligibilityCheckStatus;
  statusCodes?: Maybe<Array<Scalars['String']['output']>>;
  firstVisitCopays?: Maybe<Array<Maybe<FirstVisitCopay>>>;
  groupId?: Maybe<Scalars['String']['output']>;
  groupDescription?: Maybe<Scalars['String']['output']>;
};

export enum EligibilityCheckStatus {
  CERTIFIED = 'CERTIFIED',
  UNCERTIFIED = 'UNCERTIFIED'
}

export type FirstVisitCopay = {
  __typename?: 'FirstVisitCopay';
  type: Scalars['String']['output'];
  amount: Scalars['String']['output'];
};

export type NewMedicationV2 = {
  drugID: Scalars['ID']['input'];
  frequency?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  strength?: InputMaybe<Scalars['String']['input']>;
};

export type DiscontinueMedicationV2 = {
  medicationID: Scalars['ID']['input'];
  discontinuedReason?: InputMaybe<Scalars['String']['input']>;
};

export type MigrateMedication = {
  personIDs?: InputMaybe<Scalars['String']['input']>;
  createdAtFrom?: InputMaybe<Scalars['Time']['input']>;
  createdAtTo?: InputMaybe<Scalars['Time']['input']>;
};

export type MigrateMedicationResult = {
  __typename?: 'MigrateMedicationResult';
  personIDs?: Maybe<Array<Scalars['String']['output']>>;
  eventID: Scalars['String']['output'];
  errorMessages?: Maybe<Array<Maybe<MigrateMedicationErrors>>>;
};

export type MigrateMedicationErrors = {
  __typename?: 'MigrateMedicationErrors';
  personID?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ExportMedication = {
  personIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  folderID?: InputMaybe<Scalars['String']['input']>;
};

export type ExportMedicationResult = {
  __typename?: 'ExportMedicationResult';
  exportID: Scalars['String']['output'];
  errorMessages?: Maybe<Array<Maybe<ExportMedicationErrors>>>;
  exportFileName: Scalars['String']['output'];
};

export type ExportMedicationErrors = {
  __typename?: 'ExportMedicationErrors';
  personID?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MigrationUpdateStatus = {
  migrationIDs: Scalars['String']['input'];
  status: MigrationStatus;
};

export type MessagingMigrationUpdateResult = {
  __typename?: 'MessagingMigrationUpdateResult';
  totalCount: Scalars['Int']['output'];
  records: Array<Maybe<MigrationUpdate>>;
};

export type MigrationUpdate = {
  __typename?: 'MigrationUpdate';
  id: Scalars['String']['output'];
  status: MigrationStatus;
};

export type EncounterMigrationUpdateResult = {
  __typename?: 'EncounterMigrationUpdateResult';
  totalCount: Scalars['Int']['output'];
  records: Array<Maybe<MigrationUpdate>>;
};

export type DocumentMigrationUpdateResult = {
  __typename?: 'DocumentMigrationUpdateResult';
  totalCount: Scalars['Int']['output'];
  records: Array<Maybe<MigrationUpdate>>;
};

export type PatientUpsertV2 = {
  stripeApiId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  inactive: Scalars['Boolean']['input'];
  portalAccess: Scalars['Boolean']['input'];
  dob: Scalars['String']['input'];
  hintId?: InputMaybe<Scalars['String']['input']>;
  email1: Scalars['String']['input'];
  sex: Scalars['String']['input'];
  phoneHome?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<PatientAddressV2>;
  insurance?: InputMaybe<PatientInsuranceV2>;
  status: Scalars['String']['input'];
  insuranceType?: InputMaybe<Scalars['String']['input']>;
  primaryProviderId?: InputMaybe<Scalars['ID']['input']>;
  helpScoutID?: InputMaybe<Scalars['String']['input']>;
  healthCoachID?: InputMaybe<Scalars['String']['input']>;
  careManagerID?: InputMaybe<Scalars['String']['input']>;
  isAssignedProviderChanged: Scalars['Boolean']['input'];
  isUpdateOnCreated: Scalars['Boolean']['input'];
};

export type PatientAddressV2 = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
};

export type PatientInsuranceV2 = {
  insurancePlan?: InputMaybe<Scalars['String']['input']>;
  insuranceGroup?: InputMaybe<Scalars['String']['input']>;
  insuranceId?: InputMaybe<Scalars['String']['input']>;
  relationshipToInsured?: InputMaybe<Scalars['String']['input']>;
  insuredNameFirst?: InputMaybe<Scalars['String']['input']>;
  insuredNameMiddle?: InputMaybe<Scalars['String']['input']>;
  insuredNameLast?: InputMaybe<Scalars['String']['input']>;
  insuredAddress?: InputMaybe<Scalars['String']['input']>;
  insuredCity?: InputMaybe<Scalars['String']['input']>;
  insuredState?: InputMaybe<Scalars['String']['input']>;
  insuredZip?: InputMaybe<Scalars['String']['input']>;
  insuredDob?: InputMaybe<Scalars['String']['input']>;
  insuredPolicyNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PatientTagUpsertV2 = {
  name: Scalars['String']['input'];
  tagCategory: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  dateApplied: Scalars['String']['input'];
};

export type PatientCustomPropertiesUpsertV2 = {
  drparsleyID?: InputMaybe<Scalars['String']['input']>;
  helpscoutID?: InputMaybe<Scalars['String']['input']>;
};

export type PatientCustomPropertiesV2 = {
  __typename?: 'PatientCustomPropertiesV2';
  id: Scalars['String']['output'];
  drParsleyID?: Maybe<Scalars['String']['output']>;
  helpScoutID?: Maybe<Scalars['String']['output']>;
};

export type UpdatePersonV2 = {
  bannerNames?: InputMaybe<Scalars['String']['input']>;
};

export type AddEmrProviderInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateEmrProviderInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BatchUpdateEmrProvidersSettingInput = {
  whitelist?: InputMaybe<Array<Scalars['String']['input']>>;
  blacklist?: InputMaybe<Array<Scalars['String']['input']>>;
  delegateProvider?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  autoCreateConvoForCareTeam?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BatchUpdateEmrProvidersSettingResult = {
  __typename?: 'BatchUpdateEmrProvidersSettingResult';
  affectedProviders?: Maybe<Array<Scalars['String']['output']>>;
  error?: Maybe<Scalars['String']['output']>;
};

export type SettingUpsertInput = {
  group?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type NewSupplementV2 = {
  supplementID: Scalars['ID']['input'];
  frequency?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  strength?: InputMaybe<Scalars['String']['input']>;
};

export type DiscontinueSupplementV2 = {
  supplementID: Scalars['ID']['input'];
  discontinuedNote?: InputMaybe<Scalars['String']['input']>;
};

export type LabOrderInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  personID?: InputMaybe<Scalars['ID']['input']>;
  VitalTestPanelIDs: Array<Scalars['UUID']['input']>;
  MDHQOrderItemSourceIDs: Array<Scalars['UUID']['input']>;
  MDHQOrderGroupSourceIDs: Array<Scalars['UUID']['input']>;
  LabPreorderItemSourceIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InsuranceLabOrderInput = {
  personID: Scalars['ID']['input'];
  perferredLab?: InputMaybe<Scalars['String']['input']>;
  MDHQOrderItemSourceIDs: Array<Scalars['UUID']['input']>;
  MDHQOrderGroupSourceIDs: Array<Scalars['UUID']['input']>;
};

export type PapiScheduleAppointmentInput = {
  appointmentID: Scalars['ID']['input'];
  personID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  timezone: Scalars['String']['input'];
  slotID: Scalars['ID']['input'];
  isComplimentary: Scalars['Boolean']['input'];
  metadata?: InputMaybe<Scalars['String']['input']>;
  shouldEndFreeTrial?: InputMaybe<Scalars['Boolean']['input']>;
  providerID?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  providerAppointmentTypeID?: InputMaybe<Scalars['ID']['input']>;
};

export type PapiRescheduleAppointmentInput = {
  appointmentID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  timezone: Scalars['String']['input'];
  newSlotID: Scalars['ID']['input'];
  isComplimentary: Scalars['Boolean']['input'];
  metadata?: InputMaybe<Scalars['String']['input']>;
  shouldEndFreeTrial?: InputMaybe<Scalars['Boolean']['input']>;
  providerID?: InputMaybe<Scalars['ID']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  forceUpdateProvider?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  providerAppointmentTypeID?: InputMaybe<Scalars['ID']['input']>;
};

export type PapiCreateAppointmentInput = {
  centerID: Scalars['ID']['input'];
  personID: Scalars['ID']['input'];
  planPurchaseID: Scalars['ID']['input'];
  typeID: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
  isComplimentary: Scalars['Boolean']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type PapiUpdateAppointmentInput = {
  appointmentID: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  isComplimentary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PapiAddCouponToPurchaseInput = {
  couponSlug: Scalars['String']['input'];
  purchaseID: Scalars['ID']['input'];
};

export type PapiCreateCouponInput = {
  slug: Scalars['String']['input'];
  description: Scalars['String']['input'];
  discount: PapiCouponDiscountInput;
  duration: PapiCouponDurationInput;
  expires?: InputMaybe<Scalars['String']['input']>;
  /** Maximum amount of times coupon can be redeemed */
  maxRedemptions?: InputMaybe<Scalars['Int']['input']>;
  /** List of ids of the centers that coupon can be applied */
  centerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** List of types of products that coupon applies to */
  products?: InputMaybe<Array<PapiProductType>>;
  /** List of ids of plans that coupon can be applied */
  planIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PapiCouponDiscountInput = {
  amountOffCents?: InputMaybe<Scalars['Int']['input']>;
  percentOff?: InputMaybe<Scalars['Float']['input']>;
};

export type PapiCouponDurationInput = {
  apply?: InputMaybe<PapiCouponApplication>;
  months?: InputMaybe<Scalars['Int']['input']>;
};

export type PapiAddInsuranceSubscriberInput = {
  personId: Scalars['ID']['input'];
  relationshipToPatient: Scalars['String']['input'];
  policyNumber: Scalars['String']['input'];
  payerId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  planStartDate?: InputMaybe<Scalars['String']['input']>;
  planEndDate?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
  sex: Scalars['String']['input'];
  country: Scalars['String']['input'];
  copayAmountInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type PapiUpdateInsuranceSubscriberInput = {
  id: Scalars['ID']['input'];
  personId: Scalars['ID']['input'];
  relationshipToPatient: Scalars['String']['input'];
  policyNumber: Scalars['String']['input'];
  payerId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  planStartDate?: InputMaybe<Scalars['String']['input']>;
  planEndDate?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
  sex: Scalars['String']['input'];
  country: Scalars['String']['input'];
  copayAmountInCents?: InputMaybe<Scalars['Int']['input']>;
};

export type PapiSetInvoicePaymentStatusInput = {
  invoiceID: Scalars['ID']['input'];
  newStatus: PapiPaymentStatus;
};

export type PapiLeadDataInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  biologicalSex?: InputMaybe<PapiSex>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  partnerID?: InputMaybe<Scalars['ID']['input']>;
  parentPartnerDisplayName?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  recaptcha?: InputMaybe<PapiRecaptchaInput>;
};

export type PapiLeadDataResponse = {
  __typename?: 'PapiLeadDataResponse';
  status: Scalars['Boolean']['output'];
};

export type PapiSignAgreementInput = {
  personID: Scalars['ID']['input'];
  agreementIds: Array<Scalars['ID']['input']>;
};

export type PapiRegisterPaymentCardWithPersonInput = {
  personID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  last4Digits: Scalars['String']['input'];
  expiryMonth: Scalars['Int']['input'];
  expiryYear: Scalars['Int']['input'];
  stripeSourceToken: Scalars['String']['input'];
};

export type PapiUpdateCouponInput = {
  slug: Scalars['String']['input'];
  maxRedemptions?: InputMaybe<Scalars['Int']['input']>;
  redeemBy?: InputMaybe<Scalars['String']['input']>;
  centerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  productTypes?: InputMaybe<Array<PapiProductType>>;
  planIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PapiCreateSetupIntentResponse = {
  __typename?: 'PapiCreateSetupIntentResponse';
  personID: Scalars['ID']['output'];
  stripeCustomerID: Scalars['String']['output'];
  setupIntentClientSecret: Scalars['String']['output'];
};

export type PapiPersonEmergencyContactInput = {
  personId: Scalars['ID']['input'];
  relationship: PapiRelationship;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
};

export type PapiCreatePersonInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  biologicalSex: PapiSex;
  genderIdentity?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  centerID: Scalars['ID']['input'];
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode: Scalars['String']['input'];
  patientAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  insurancePayer?: InputMaybe<Scalars['String']['input']>;
  insuranceGroupID?: InputMaybe<Scalars['String']['input']>;
  insuranceMemberID?: InputMaybe<Scalars['String']['input']>;
  hipaaAuthorizationAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  termsOfUseAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  membershipTermsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  selfPaymentOfServicesTermsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  supplementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  clinicalMembershipAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  membershipAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  assignmentOfBenefitsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  creditCardAuthAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PapiRole>;
  sendPasswordReset: Scalars['Boolean']['input'];
  recaptcha?: InputMaybe<PapiRecaptchaInput>;
  noticePrivacyPracticesAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  telehealthInformedConsentAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  telehealthAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  assignedDoctorID?: InputMaybe<Scalars['ID']['input']>;
  assignedCoachID?: InputMaybe<Scalars['ID']['input']>;
  onboardingFlowSlug?: InputMaybe<Scalars['String']['input']>;
  analyticsID?: InputMaybe<Scalars['ID']['input']>;
  enrolledBy?: InputMaybe<Scalars['String']['input']>;
};

export type PapiUpdatePersonInput = {
  id: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  biologicalSex?: InputMaybe<PapiSex>;
  genderIdentity?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  centerID?: InputMaybe<Scalars['ID']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  patientAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  telehealthAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  insurancePayer?: InputMaybe<Scalars['String']['input']>;
  insuranceGroupID?: InputMaybe<Scalars['String']['input']>;
  insuranceMemberID?: InputMaybe<Scalars['String']['input']>;
  hipaaAuthorizationAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  helpScoutID?: InputMaybe<Scalars['ID']['input']>;
  termsOfUseAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  membershipTermsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  selfPaymentOfServicesTermsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  supplementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  clinicalMembershipAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  membershipAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  assignmentOfBenefitsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  creditCardAuthAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PapiRole>;
  enrolledBy?: InputMaybe<Scalars['String']['input']>;
  newMDHQID?: InputMaybe<Scalars['ID']['input']>;
  noticePrivacyPracticesAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  telehealthInformedConsentAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  assignedDoctorID?: InputMaybe<Scalars['ID']['input']>;
  assignedCoachID?: InputMaybe<Scalars['ID']['input']>;
};

export type PapiDeleteResponse = {
  __typename?: 'PapiDeleteResponse';
  personResponse: Scalars['String']['output'];
  helpScoutResponse: Scalars['String']['output'];
  mdhqResponse: Scalars['String']['output'];
  hubspotResponse: Scalars['String']['output'];
  firebaseResponse: Scalars['String']['output'];
};

export type PapiUpdateCurrentPapiPersonInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  biologicalSex?: InputMaybe<PapiSex>;
  genderIdentity?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  centerID?: InputMaybe<Scalars['ID']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  patientAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  telehealthAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  insurancePayer?: InputMaybe<Scalars['String']['input']>;
  insuranceGroupID?: InputMaybe<Scalars['String']['input']>;
  insuranceMemberID?: InputMaybe<Scalars['String']['input']>;
  hipaaAuthorizationAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  termsOfUseAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  membershipTermsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  selfPaymentOfServicesTermsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  supplementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  clinicalMembershipAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  membershipAgreementAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  assignmentOfBenefitsAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  creditCardAuthAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PapiRole>;
  noticePrivacyPracticesAcceptedAt?: InputMaybe<Scalars['String']['input']>;
  telehealthInformedConsentAcceptedAt?: InputMaybe<Scalars['String']['input']>;
};

export type PapiRectifyThirdPartiesPayload = {
  __typename?: 'PapiRectifyThirdPartiesPayload';
  person: PapiPerson;
  helpScoutResponse: Scalars['String']['output'];
  mdhqResponse: Scalars['String']['output'];
  hubspotResponse: Scalars['String']['output'];
  storeResponse: Scalars['String']['output'];
  firebaseResponse: Scalars['String']['output'];
};

export type PapiUpdateInsuranceInput = {
  personID: Scalars['ID']['input'];
  groupID?: InputMaybe<Scalars['String']['input']>;
  memberID: Scalars['String']['input'];
  payer: Scalars['String']['input'];
};

export type PapiCreateInsightsUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  biologicalSex?: InputMaybe<PapiSex>;
  centerID: Scalars['ID']['input'];
  sendPasswordReset: Scalars['Boolean']['input'];
};

export type PapiInsightUser = {
  __typename?: 'PapiInsightUser';
  id: Scalars['ID']['output'];
  firebaseCustomRegistrationToken?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type PapiTransferCareInput = {
  personID: Scalars['String']['input'];
  providerID?: InputMaybe<Scalars['String']['input']>;
  currentProviderID?: InputMaybe<Scalars['String']['input']>;
  transferType: PapiTransferOfCareTypeInput;
};

export enum PapiTransferOfCareTypeInput {
  CLINICAL = 'CLINICAL',
  COACHING = 'COACHING'
}

export type PapiTransitionPlanPurchaseInput = {
  personID: Scalars['ID']['input'];
  purchaseID: Scalars['ID']['input'];
  planID: Scalars['ID']['input'];
};

export type PapiMakeNewPurchaseInput = {
  payorID: Scalars['ID']['input'];
  planID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  /** undefined startTime sets the plan to start immediately */
  startTime?: InputMaybe<Scalars['String']['input']>;
  couponSlug?: InputMaybe<Scalars['String']['input']>;
  personID: Scalars['ID']['input'];
  followOnPurchase?: InputMaybe<PapiFollowOnPurchaseInput>;
  addDefaultFollowOn?: InputMaybe<Scalars['Boolean']['input']>;
  iterateOverFollowOns?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PapiFollowOnPurchaseInput = {
  planID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  couponSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PapiMakeNewPurchasePayload = {
  __typename?: 'PapiMakeNewPurchasePayload';
  planPurchase: PapiPlanPurchase;
  followOnPurchase?: Maybe<PapiPlanPurchase>;
};

export type PapiMakeNewB2BPurchaseInput = {
  payorID: Scalars['ID']['input'];
  planID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  /** undefined startTime sets the plan to start immediately */
  startTime?: InputMaybe<Scalars['String']['input']>;
  personID: Scalars['ID']['input'];
  couponSlug?: InputMaybe<Scalars['String']['input']>;
  partnerSlug: Scalars['String']['input'];
};

export type PapiCancelPurchaseInput = {
  personID: Scalars['ID']['input'];
  purchaseID: Scalars['ID']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  cancelFollowingPurchases?: InputMaybe<Scalars['Boolean']['input']>;
  cancelUpcomingAppointments?: InputMaybe<Scalars['Boolean']['input']>;
  overrideToEndAfterNextBill?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PapiChangeFollowOnPlanInput = {
  personID: Scalars['ID']['input'];
  currentPurchaseID: Scalars['ID']['input'];
  followOnPurchaseID: Scalars['ID']['input'];
  planID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  couponSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PapiModifyFollowOnPlanInput = {
  personID: Scalars['ID']['input'];
  planID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  couponSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PapiExtendPurchaseInput = {
  personID: Scalars['ID']['input'];
  currentPurchaseID: Scalars['ID']['input'];
  planID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
};

export type PapiAddFollowOnPlanInput = {
  currentPurchaseID: Scalars['ID']['input'];
  planID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  couponSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PapiChangePlanImmediatelyInput = {
  currentPurchaseID: Scalars['ID']['input'];
  newPlanID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  couponSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PapiChangePlanAtCommitmentEnd = {
  personID: Scalars['ID']['input'];
  newPlanID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  couponSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PapiEnrollFromWaitlistInput = {
  purchaseID: Scalars['ID']['input'];
  addDefaultFollowOn?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  newPlanID?: InputMaybe<Scalars['ID']['input']>;
  couponSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PapiPausePurchaseInput = {
  purchaseID: Scalars['ID']['input'];
  daysToPause: Scalars['Int']['input'];
  /** starts the pause immediately and ends it exactly `daysToPause` days later */
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type PapiCreatePlanInput = {
  displayName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  productType: PapiProductType;
  billingPeriod: PapiBillingPeriod;
  numberOfBillingCycles?: InputMaybe<Scalars['Int']['input']>;
  paymentRateInCents: Scalars['Int']['input'];
  minPersonAgeYears: Scalars['Int']['input'];
  maxPersonAgeYears?: InputMaybe<Scalars['Int']['input']>;
  defaultFollowOnPlanID?: InputMaybe<Scalars['ID']['input']>;
  /** optional commitment period as day month or year etc for which this plan is contracted */
  commitmentLength?: InputMaybe<PapiBillingPeriod>;
  /** list of center ids this plan is allowed to be purchased as a first purchase by a person with a role (<) parsley_staff */
  joinPurchaseAllowedCenterIDs: Array<Scalars['ID']['input']>;
};

export type PapiInitiateOneTimePurchaseResponse = {
  __typename?: 'PapiInitiateOneTimePurchaseResponse';
  personId: Scalars['ID']['output'];
  planId: Scalars['ID']['output'];
  centerId: Scalars['ID']['output'];
  stripeCustomerId: Scalars['String']['output'];
  paymentIntentClientSecret: Scalars['String']['output'];
};

export type PapiFinalizeOneTimePurchaseInput = {
  personId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  centerId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['String']['input']>;
  paymentIntentId: Scalars['String']['input'];
};

export type PapiUpdatePlanJoinableCentersInput = {
  ID: Scalars['String']['input'];
  /** list of center ids this plan is allowed to be purchased as a first purchase by a person with a role (<) parsley_staff */
  joinPurchaseAllowedCenterIDs: Array<Scalars['ID']['input']>;
};

export type PapiProviderAttrInput = {
  providerID: Scalars['ID']['input'];
  attr: PapiProviderAttrType;
  value: Scalars['String']['input'];
};

export type PapiUpdateProviderInput = {
  id: Scalars['ID']['input'];
  takeShapeID?: InputMaybe<Scalars['ID']['input']>;
  sanityID?: InputMaybe<Scalars['ID']['input']>;
  acuityID?: InputMaybe<Scalars['ID']['input']>;
  mdhqId?: InputMaybe<Scalars['String']['input']>;
  helpScoutID?: InputMaybe<Scalars['ID']['input']>;
  /** CareManagerID, if null, the care manager for this entity will be unassigned (If any). */
  careManagerID?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PapiProviderType>;
  employmentType?: InputMaybe<PapiEmploymentType>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  professionalSuffix?: InputMaybe<PapiProfessionalSuffix>;
  biologicalSex?: InputMaybe<PapiSex>;
  photoURL?: InputMaybe<Scalars['String']['input']>;
  acuityInstance?: InputMaybe<PapiAcuityInstance>;
  centers?: InputMaybe<Array<PapiProviderCenterInput>>;
  payerCredentials?: InputMaybe<Array<PapiProviderPayerCredentialsInput>>;
  products?: InputMaybe<Array<PapiProductType>>;
  targetPanelSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PapiProviderCenterInput = {
  centerID: Scalars['ID']['input'];
  takingNewPatients: Scalars['Boolean']['input'];
};

export type PapiProviderPayerCredentialsInput = {
  payerID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
};

export type PapiAddProviderInput = {
  acuityID?: InputMaybe<Scalars['ID']['input']>;
  acuityInstance?: InputMaybe<PapiAcuityInstance>;
  biologicalSex: PapiSex;
  centers: Array<PapiProviderCenterInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  helpScoutID?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  photoURL?: InputMaybe<Scalars['String']['input']>;
  professionalSuffix?: InputMaybe<PapiProfessionalSuffix>;
  sanityID?: InputMaybe<Scalars['ID']['input']>;
  takeShapeID?: InputMaybe<Scalars['ID']['input']>;
  type: PapiProviderType;
  employmentType?: InputMaybe<PapiEmploymentType>;
  products?: InputMaybe<Array<PapiProductType>>;
};

export type PapiUpsertProviderCenterInput = {
  providerID: Scalars['ID']['input'];
  centerID: Scalars['ID']['input'];
  takingNewPatients: Scalars['Boolean']['input'];
};

export type PapiUpdateProviderWithCentersInput = {
  id: Scalars['ID']['input'];
  takeShapeID?: InputMaybe<Scalars['ID']['input']>;
  sanityID?: InputMaybe<Scalars['ID']['input']>;
  acuityID?: InputMaybe<Scalars['ID']['input']>;
  helpScoutID?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PapiProviderType>;
  employmentType?: InputMaybe<PapiEmploymentType>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  professionalSuffix?: InputMaybe<PapiProfessionalSuffix>;
  biologicalSex?: InputMaybe<PapiSex>;
  photoURL?: InputMaybe<Scalars['String']['input']>;
  acuityInstance?: InputMaybe<PapiAcuityInstance>;
  centers?: InputMaybe<Array<PapiProviderCenterInput>>;
};

export type PapiAddProviderWithCentersInput = {
  acuityID?: InputMaybe<Scalars['ID']['input']>;
  acuityInstance?: InputMaybe<PapiAcuityInstance>;
  biologicalSex: PapiSex;
  centers: Array<PapiProviderCenterInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  helpScoutID?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  photoURL?: InputMaybe<Scalars['String']['input']>;
  professionalSuffix?: InputMaybe<PapiProfessionalSuffix>;
  sanityID?: InputMaybe<Scalars['ID']['input']>;
  takeShapeID?: InputMaybe<Scalars['ID']['input']>;
  type: PapiProviderType;
  employmentType?: InputMaybe<PapiEmploymentType>;
};

export type PapiAddProviderPayerCredentialsInput = {
  providerID: Scalars['ID']['input'];
  payerCredentials: Array<PapiProviderPayerCredentialsInput>;
};

export type PapiRemoveProviderPayerCredentialsInput = {
  providerID: Scalars['ID']['input'];
  payerCredentials: PapiProviderPayerCredentialsInput;
};

export type PapiAcceptQuestionnairesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type PapiAcceptQuestionnairesResponse = {
  __typename?: 'PapiAcceptQuestionnairesResponse';
  questionnaireID: Scalars['ID']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type PapiCreateQuestionnaireInput = {
  type: PapiQuestionnaireType;
};

export type PapiSubmitQuestionnaireInput = {
  id: Scalars['ID']['input'];
  onlyUpdateStatus?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PapiUpdateQuestionnaireInput = {
  id: Scalars['ID']['input'];
  answers: Scalars['JSON']['input'];
};

export type CreateImawarePatientInput = {
  id: Scalars['ID']['input'];
  biologicalSex: AdministrativeGender;
  address: AddressInput;
  phoneNumber: Scalars['String']['input'];
};

export type AddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type File = {
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  base64Content: Scalars['String']['input'];
};

export type ChatGptInput = {
  messages: Array<Message>;
  model: Scalars['String']['input'];
};

export type Message = {
  role: Scalars['String']['input'];
  content: Scalars['String']['input'];
};

export type ChatCompletionResponse = {
  __typename?: 'ChatCompletionResponse';
  message: Scalars['String']['output'];
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  id: Scalars['String']['output'];
  sections?: Maybe<Array<QuestionnaireSection>>;
  metadata?: Maybe<Scalars['String']['output']>;
};

export type QuestionnaireSection = {
  __typename?: 'QuestionnaireSection';
  title: Scalars['String']['output'];
  forms?: Maybe<Array<QuestionnaireForm>>;
  status: QuestionnaireSectionStatus;
};

export type QuestionnaireForm = {
  __typename?: 'QuestionnaireForm';
  release_id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  definition_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  questions: Array<QuestionnaireQuestion>;
  metadata?: Maybe<Scalars['String']['output']>;
  status: QuestionnaireFormStatus;
};

export type QuestionnaireQuestion = {
  __typename?: 'QuestionnaireQuestion';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  definition_id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  dataPointValueType?: Maybe<QuestionnaireDataPointValueType>;
  options?: Maybe<Array<QuestionnaireOption>>;
  questionType?: Maybe<QuestionnaireQuestionType>;
  userQuestionType?: Maybe<QuestionnaireUserQuestionType>;
  questionConfig?: Maybe<QuestionnaireQuestionConfig>;
  rule?: Maybe<QuestionnaireRule>;
  answer?: Maybe<QuestionnaireQuestionAnswer>;
  metadata?: Maybe<Scalars['String']['output']>;
};

export enum QuestionnaireDataPointValueType {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  NUMBERS_ARRAY = 'NUMBERS_ARRAY',
  TELEPHONE = 'TELEPHONE'
}

export type QuestionnaireOption = {
  __typename?: 'QuestionnaireOption';
  id: Scalars['ID']['output'];
  value: Scalars['Float']['output'];
  label: Scalars['String']['output'];
};

export enum QuestionnaireQuestionType {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  INPUT = 'INPUT',
  NO_INPUT = 'NO_INPUT'
}

export enum QuestionnaireUserQuestionType {
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  NUMBER = 'NUMBER',
  YES_NO = 'YES_NO',
  DATE = 'DATE',
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  SLIDER = 'SLIDER',
  DESCRIPTION = 'DESCRIPTION',
  MULTIPLE_CHOICE_GRID = 'MULTIPLE_CHOICE_GRID',
  SIGNATURE = 'SIGNATURE',
  TELEPHONE = 'TELEPHONE'
}

export type QuestionnaireQuestionConfig = {
  __typename?: 'QuestionnaireQuestionConfig';
  recode_enabled?: Maybe<Scalars['Boolean']['output']>;
  mandatory: Scalars['Boolean']['output'];
  use_select?: Maybe<Scalars['Boolean']['output']>;
  slider?: Maybe<QuestionnaireSliderConfig>;
};

export type QuestionnaireSliderConfig = {
  __typename?: 'QuestionnaireSliderConfig';
  min: Scalars['Float']['output'];
  max: Scalars['Float']['output'];
  step_value: Scalars['Float']['output'];
  min_label: Scalars['String']['output'];
  max_label: Scalars['String']['output'];
  is_value_tooltip_on: Scalars['Boolean']['output'];
  display_marks: Scalars['Boolean']['output'];
  show_min_max_values: Scalars['Boolean']['output'];
};

export type QuestionnaireRule = {
  __typename?: 'QuestionnaireRule';
  id: Scalars['ID']['output'];
  definition_id?: Maybe<Scalars['String']['output']>;
  boolean_operator: BooleanOperator;
  conditions: Array<QuestionnaireCondition>;
};

export enum BooleanOperator {
  AND = 'AND',
  OR = 'OR'
}

export type QuestionnaireCondition = {
  __typename?: 'QuestionnaireCondition';
  id: Scalars['ID']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  reference_key?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<QuestionnaireConditionOperator>;
  operand?: Maybe<QuestionnaireOperand>;
};

export enum QuestionnaireConditionOperator {
  IS_EQUAL_TO = 'IS_EQUAL_TO',
  IS_NOT_EQUAL_TO = 'IS_NOT_EQUAL_TO',
  IS_GREATER_THAN = 'IS_GREATER_THAN',
  IS_LESS_THAN = 'IS_LESS_THAN',
  IS_GREATER_THAN_OR_EQUAL_TO = 'IS_GREATER_THAN_OR_EQUAL_TO',
  IS_LESS_THAN_OR_EQUAL_TO = 'IS_LESS_THAN_OR_EQUAL_TO',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  IS_TRUE = 'IS_TRUE',
  IS_NOT_TRUE = 'IS_NOT_TRUE',
  IS_IN_RANGE = 'IS_IN_RANGE',
  IS_ANY_OF = 'IS_ANY_OF',
  IS_NONE_OF = 'IS_NONE_OF',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
  IS_TODAY = 'IS_TODAY'
}

export type QuestionnaireOperand = {
  __typename?: 'QuestionnaireOperand';
  type: QuestionnaireConditionOperandType;
  value: Scalars['String']['output'];
};

export enum QuestionnaireConditionOperandType {
  DATA_SOURCE = 'DATA_SOURCE',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  NUMBERS_ARRAY = 'NUMBERS_ARRAY',
  DATA_POINT = 'DATA_POINT'
}

export type QuestionnaireQuestionAnswer = {
  __typename?: 'QuestionnaireQuestionAnswer';
  valueType: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum QuestionnaireFormStatus {
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE'
}

export enum QuestionnaireSectionStatus {
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE'
}

export type EvaluateQuestionnaireFormInput = {
  questionnaireID: Scalars['String']['input'];
  formID: Scalars['String']['input'];
  responses?: InputMaybe<Array<FormQuestionResponse>>;
};

export type FormQuestionResponse = {
  questionID: Scalars['String']['input'];
  value: Scalars['String']['input'];
  valueType: Scalars['String']['input'];
};

export type SubmitQuestionnaireFormInput = {
  questionnaireType: Scalars['String']['input'];
  questionnaireInternalID: Scalars['String']['input'];
  questionnaireID: Scalars['String']['input'];
  formID: Scalars['String']['input'];
  isLastForm?: InputMaybe<Scalars['Boolean']['input']>;
  isSaveAndExit?: InputMaybe<Scalars['Boolean']['input']>;
  responses?: InputMaybe<Array<FormQuestionResponse>>;
};

export type QuestionnaireConfigCacheInput = {
  type: Scalars['String']['input'];
  config: Scalars['String']['input'];
};

export type QuestionnaireSettingInput = {
  group?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AttributeFilter = {
  pagination?: InputMaybe<PaginationRequest>;
  include?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CurrentPersonMetadataResponse = {
  __typename?: 'CurrentPersonMetadataResponse';
  id: Scalars['ID']['output'];
  personId: Scalars['ID']['output'];
  updatedPasswordAt: Scalars['Time']['output'];
  isForceResetPassword?: Maybe<Scalars['Boolean']['output']>;
  passwordExpiresIn: Scalars['Time']['output'];
  passwordState: PasswordState;
};

export enum PasswordState {
  CURRENT = 'CURRENT',
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED = 'EXPIRED'
}

export type DeletePartnerEmployeeAttributesRequest = {
  partnerEmployeeID: Scalars['ID']['input'];
  attributes: Array<Scalars['String']['input']>;
};

export type DeletePartnerEmployeeAttributesResponse = {
  __typename?: 'DeletePartnerEmployeeAttributesResponse';
  partnerEmployeeID: Scalars['ID']['output'];
  affectedRows: Scalars['Int']['output'];
};

export type DocumentV2Result = {
  __typename?: 'DocumentV2Result';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['Cursor']['output']>;
  records: Array<DocumentV2>;
};

export type EncounterV2Filter = {
  pagination?: InputMaybe<PaginationRequest>;
};

export type EncounterV2Result = {
  __typename?: 'EncounterV2Result';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['Cursor']['output']>;
  records: Array<EncounterV2>;
};

export enum Feature {
  MESSAGING = 'MESSAGING',
  MEDICATION = 'MEDICATION',
  SUPPLEMENT = 'SUPPLEMENT',
  ENCOUNTER = 'ENCOUNTER'
}

export type LabMarker = {
  __typename?: 'LabMarker';
  id: Scalars['UUID']['output'];
  hashes?: Maybe<Array<Scalars['UUID']['output']>>;
  name: Scalars['String']['output'];
  units: Scalars['String']['output'];
  range: LabRangeDetail;
  key: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  deletedAt?: Maybe<Scalars['Time']['output']>;
};

export type LabTest = {
  __typename?: 'LabTest';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  description: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  recommended: Scalars['Boolean']['output'];
  createdAt: Scalars['Time']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type MedicationV2Filter = {
  pagination?: InputMaybe<PaginationRequest>;
};

export type MedicationV2Result = {
  __typename?: 'MedicationV2Result';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['Cursor']['output']>;
  records: Array<MedicationV2>;
};

export type PapiCenterInput = {
  name: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export type PapiNextAppointmentType = {
  __typename?: 'PapiNextAppointmentType';
  category: PapiAppointmentCategory;
  format: PapiAppointmentFormat;
};

export type PapiNextAppointmentTypes = {
  __typename?: 'PapiNextAppointmentTypes';
  medicalsList: Array<PapiNextAppointmentType>;
  coachingsList: Array<PapiNextAppointmentType>;
  labsList: Array<PapiNextAppointmentType>;
  consultationsList: Array<PapiNextAppointmentType>;
  medicalAssessmentList: Array<PapiNextAppointmentType>;
  careManagerList: Array<PapiNextAppointmentType>;
};

export type PapiPurchasePeriod = {
  __typename?: 'PapiPurchasePeriod';
  id: Scalars['ID']['output'];
  purchaseId: Scalars['ID']['output'];
  personId: Scalars['ID']['output'];
  startTime?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
};

export type PasswordMetadata = {
  __typename?: 'PasswordMetadata';
  id: Scalars['ID']['output'];
  personId: Scalars['ID']['output'];
  updatedPasswordAt: Scalars['Time']['output'];
  isForceResetPassword: Scalars['Boolean']['output'];
  resetPasswordSentAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
};

export type PasswordMetadataResult = {
  __typename?: 'PasswordMetadataResult';
  totalCount: Scalars['Int']['output'];
  nextCursor?: Maybe<Scalars['String']['output']>;
  records: Array<PasswordMetadata>;
};

export enum PsiCategory {
  CARDIAC = 'CARDIAC',
  GASTROINTESTINAL = 'GASTROINTESTINAL',
  METABOLIC = 'METABOLIC',
  HAIR_SKIN = 'HAIR_SKIN',
  NEUROLOGICAL = 'NEUROLOGICAL',
  RESPIRATORY = 'RESPIRATORY',
  MUSCULOSKELETAL = 'MUSCULOSKELETAL',
  MENTAL = 'MENTAL',
  REPRODUCTIVE_GENITAL = 'REPRODUCTIVE_GENITAL',
  OTHER_STRESS = 'OTHER_STRESS',
  OTHER_COLD = 'OTHER_COLD'
}

export type SanityBlogCategory = {
  __typename?: 'SanityBlogCategory';
  _id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type SanityBlogPost = {
  __typename?: 'SanityBlogPost';
  _id: Scalars['ID']['output'];
  _type: Scalars['String']['output'];
  categories: SanityBlogCategory;
  slug: SanitySlug;
  image: SanityImage;
};

export type SanitySlug = {
  __typename?: 'SanitySlug';
  current: Scalars['String']['output'];
};

export type SanityImage = {
  __typename?: 'SanityImage';
  asset: SanityImageAsset;
  alt: Scalars['String']['output'];
};

export type SanityImageAsset = {
  __typename?: 'SanityImageAsset';
  path: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SanityBlogVideo = {
  __typename?: 'SanityBlogVideo';
  _id: Scalars['ID']['output'];
  slug: SanitySlug;
  videoEmbedCode: Scalars['String']['output'];
  image: SanityImage;
};

export type SanityVideo = {
  __typename?: 'SanityVideo';
  asset?: Maybe<SanityVideoAsset>;
};

export type SanityVideoAsset = {
  __typename?: 'SanityVideoAsset';
  assetId?: Maybe<Scalars['String']['output']>;
  playbackId?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  thumbTime?: Maybe<Scalars['Int']['output']>;
};
