import gql from 'graphql-tag';

export const LIST_LEAD_ELIGIBILITY_CHECKS = gql`
  query ListLeadEligibilityChecks($limit: Int, $offset: Int) {
    listLeadEligibilityCheckResponse(limit: $limit, offset: $offset) {
      firstName
      lastName
      gender
      dateOfBirth
      centerState
      memberID
      nationalPayorID
      payorName
      planStatus
      networkStatus
      checkedAt
      description
      planNames
    }
  }
`;
