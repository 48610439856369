import { useQuery } from '@apollo/react-hooks';
import React, { FC, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { ATable } from 'app/components/atoms/ATable/ATable';
import { AInput } from 'app/components/atoms/AInput/AInput';
import { LIST_LEAD_ELIGIBILITY_CHECKS } from './query';
import {
  ListLeadEligibilityChecks,
  ListLeadEligibilityChecksVariables,
} from 'app/types/generated/ListLeadEligibilityChecks';

const PAGE_SIZE = 10;

interface LeadEligibilityResponse {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  centerState: string;
  memberID: string;
  nationalPayorID: string;
  payorName: string | null;
  planStatus: string;
  networkStatus: string;
  checkedAt: string | null;
  description: string | null;
  planNames: string | null;
}

const columns = [
  {
    title: 'Name',
    key: 'name',
    width: 150,
    render: (record: LeadEligibilityResponse) =>
      `${record.firstName} ${record.lastName}`,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      ),
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    width: 100,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      a.gender.localeCompare(b.gender),
  },
  {
    title: 'Date of Birth',
    dataIndex: 'dateOfBirth',
    key: 'dateOfBirth',
    width: 120,
    render: (date: string) => new Date(date).toLocaleDateString(),
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      new Date(a.dateOfBirth).getTime() - new Date(b.dateOfBirth).getTime(),
  },
  {
    title: 'Center',
    dataIndex: 'centerState',
    key: 'centerState',
    width: 100,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      a.centerState.localeCompare(b.centerState),
  },
  {
    title: 'Member ID',
    dataIndex: 'memberID',
    key: 'memberID',
    width: 150,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      a.memberID.localeCompare(b.memberID),
  },
  {
    title: 'Payor ID',
    dataIndex: 'nationalPayorID',
    key: 'nationalPayorID',
    width: 150,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      a.nationalPayorID.localeCompare(b.nationalPayorID),
  },
  {
    title: 'Payor Name',
    dataIndex: 'payorName',
    key: 'payorName',
    width: 150,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      (a.payorName || '').localeCompare(b.payorName || ''),
  },
  {
    title: 'Plan Name',
    dataIndex: 'planNames',
    key: 'planNames',
    width: 150,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      (a.planNames || '').localeCompare(b.planNames || ''),
  },
  {
    title: 'Plan Status',
    dataIndex: 'planStatus',
    key: 'planStatus',
    width: 120,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      a.planStatus.localeCompare(b.planStatus),
  },
  {
    title: 'Network Status',
    dataIndex: 'networkStatus',
    key: 'networkStatus',
    width: 170,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      a.networkStatus.localeCompare(b.networkStatus),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 200,
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      (a.description || '').localeCompare(b.description || ''),
  },
  {
    title: 'Checked At',
    dataIndex: 'checkedAt',
    key: 'checkedAt',
    width: 150,
    render: (date: string) =>
      date ? new Date(Number(date)).toLocaleString() : '-',
    sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) =>
      Number(a.checkedAt || 0) - Number(b.checkedAt || 0),
    defaultSortOrder: 'descend',
  },
];

const LeadEligibilityPage: FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, loading } = useQuery<
    ListLeadEligibilityChecks,
    ListLeadEligibilityChecksVariables
  >(LIST_LEAD_ELIGIBILITY_CHECKS);

  const filteredData = useMemo(() => {
    if (!searchQuery) return data?.listLeadEligibilityCheckResponse || [];

    return (data?.listLeadEligibilityCheckResponse || []).filter((record) => {
      const searchFields = [
        `${record.firstName} ${record.lastName}`,
        record.gender,
        record.dateOfBirth,
        record.centerState,
        record.memberID,
        record.nationalPayorID,
        record.payorName,
        record.planNames,
        record.planStatus,
        record.networkStatus,
        record.description,
        record.checkedAt
          ? new Date(Number(record.checkedAt)).toLocaleString()
          : '',
      ];

      return searchFields.some((field) =>
        field.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [data, searchQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  return (
    <Container>
      <SearchContainer>
        <AInput
          placeholder="Search all columns..."
          value={searchQuery}
          onChange={handleSearch}
          style={{ width: 300, marginBottom: 16 }}
        />
      </SearchContainer>
      <ATable<LeadEligibilityResponse>
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: PAGE_SIZE,
          total: filteredData.length,
          onChange: handlePageChange,
        }}
        rowKey={(record) => `${record.memberID}-${record.checkedAt}`}
        sortDirections={['ascend', 'descend']}
        scroll={{ x: 1500 }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 24px;
  overflow-x: auto;
`;

const SearchContainer = styled.div`
  margin-bottom: 2px;
`;

export { LeadEligibilityPage };
