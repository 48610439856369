import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import {
  GET_MOST_RECENT_ELIGIBILITY_CHECK_FOR_SUBSCRIBER,
  GET_REALTIME_ELIGIBILITY_CHECK_FOR_PERSON,
} from '../components/organisms/EditInsuranceForm/Query';
import {
  realtimeEligibilityCheckForPerson,
  realtimeEligibilityCheckForPersonVariables,
} from '../types/generated/realtimeEligibilityCheckForPerson';
import { message } from 'antd';
import {
  getMostRecentEligibilityCheckForSubscriber,
  getMostRecentEligibilityCheckForSubscriberVariables,
} from '../types/generated/getMostRecentEligibilityCheckForSubscriber';

const useEligibilityCheck = (personID: string, subscriberId: string) => {
  const [
    realtimeEligibilityCheckForPersonQuery,
    { data: eligibilityCheckData, error },
  ] = useLazyQuery<
    realtimeEligibilityCheckForPerson,
    realtimeEligibilityCheckForPersonVariables
  >(GET_REALTIME_ELIGIBILITY_CHECK_FOR_PERSON, {
    fetchPolicy: 'network-only',
  });

  const { data: latestEligibilityResponseData, refetch } = useQuery<
    getMostRecentEligibilityCheckForSubscriber,
    getMostRecentEligibilityCheckForSubscriberVariables
  >(GET_MOST_RECENT_ELIGIBILITY_CHECK_FOR_SUBSCRIBER, {
    variables: { id: subscriberId },
    fetchPolicy: 'network-only',
  });

  const checkEligibility = () => {
    message.loading('checking insurance status...', 3000);
    realtimeEligibilityCheckForPersonQuery({
      variables: { personId: personID },
    });
  };

  useEffect(() => {
    if (eligibilityCheckData) {
      message.destroy();
      if (eligibilityCheckData.realtimeEligibilityCheckForPerson.description) {
        message.warn(
          eligibilityCheckData.realtimeEligibilityCheckForPerson.description
        );
      } else {
        message.success('insurance status checked successfully');
      }
      refetch();
    } else if (error) {
      message.destroy();
      message.error(error.message);
    }
  }, [eligibilityCheckData, refetch, error]);

  return {
    planName:
      latestEligibilityResponseData?.getMostRecentEligibilityCheckForSubscriber
        ?.planNames[0],
    networkStatus:
      latestEligibilityResponseData?.getMostRecentEligibilityCheckForSubscriber
        ?.networkStatus,
    planStatus:
      latestEligibilityResponseData?.getMostRecentEligibilityCheckForSubscriber
        ?.planStatus,
    lastEligibilityCheck: latestEligibilityResponseData
      ?.getMostRecentEligibilityCheckForSubscriber?.checkedAt
      ? new Date(
          Number(
            latestEligibilityResponseData
              ?.getMostRecentEligibilityCheckForSubscriber?.checkedAt
          )
        ).toLocaleString()
      : 'Please check insurance status',
    checkEligibility,
    failureReason: eligibilityCheckData?.realtimeEligibilityCheckForPerson,
    followupAction: eligibilityCheckData?.realtimeEligibilityCheckForPerson,
    possibleResolution: eligibilityCheckData?.realtimeEligibilityCheckForPerson,
  };
};

export default useEligibilityCheck;
